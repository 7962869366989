@import "../common/variables";
.p_resumewriting {
  .input_description_box {
    .com_resume_progress {
      .tit {
        @include flex(y);
        width: 100%;
        font-size: 16px;
        .exclaim {
          display: inline-block;
          height: 15px;
          width: 15px;
          text-align: center;
          margin-left: 13px;
          font-size: 11px;
          border: 1px solid #c4c4c4;
          background-color: #dfdfdf;
          border-radius: 50%;
        }
      }
      .btn_wrapper {
        gap: 10px;
        .com_btn {
          margin-bottom: 0;
        }
      }
    }
    .com_btn:has(.addition) {
      margin-top: 20px;
      align-items: center;
      padding: 7px 20px;
      min-width: 80px;
      border-radius: 30px;
      margin-right: 10px;
      .addition {
        display: inline-block;
        font-weight: 100;
        margin: 0 5px 5px 0;
        font-size: 25px;
        line-height: 0;
      }
    }
    .btn_delete {
      height: 20px;
      min-width: 20px;
      aspect-ratio: 1 / 1;
      background-color: $c-border;
      border-radius: 50%;
      @include inflex(all);
      img {
        width: 60%;
      }
    }
    .form_wrapper {
      .bottom_line {
        border-bottom: 1px solid #dfdfdf;
      }
      .title_field {
        font-size: clamp(14px, 2.5vw, 18px);
        font-weight: 600;
        padding-bottom: 16px;
        border-bottom: 1px solid #dfdfdf;
        margin-top: 40px;
        @include media-max($s) {
          margin-top: 30px;
        }
        &:has(.desc, .right) {
          @include flex(between);
          @include media-max($s) {
            border: none;
            @include flex(column);
            align-items: flex-start;
            gap: 10px;
            .desc {
              margin-left: 0;
              padding-left: 0;
              border: none;
            }
            .right {
              margin-top: 10px;
            }
          }
        }
        .desc {
          margin-right: auto;
          font-size: 14px;
          font-weight: normal;
          padding-left: 10px;
          margin-left: 10px;
          border-left: 1px solid #e1e1e1;
        }
        .right {
          font-size: 14px;
          color: $point;
          &::after {
            content: "";
            display: inline-block;
            margin: 0 0 -1px 7px;
            height: 12px;
            width: 10px;
            background: url("../../img/arrow_right_active.png") no-repeat 0 0;
            background-size: contain;
          }
        }
        .required {
          font-size: 14px;
          color: $point;
        }
        &.link_tit {
          @include media-max($s) {
            @include flex(between);
            flex-direction: row;
            border-bottom: 1px solid $c-border;
            .right {
              margin-top: 0;
            }
          }
        }
      }
      .decision_field {
        @include flex(y);
        gap: 15px;
        padding-block: 20px;
        @include media-max($s) {
          flex-wrap: wrap;
        }
        .input {
          max-width: 180px;
          margin-left: 0;
        }
        .decision {
          padding-left: 40px;
          margin-left: 0;
        }
        &.column {
          @include flex(column);
          gap: 16px;
          align-items: flex-start;
        }
        .input_wrapper {
          @include flex(y);
          gap: 10px;
          @include media-max($s) {
            @include flex(column);
            align-items: flex-start;
          }
        }
        .hashtags2 {
          display: flex;
          flex-wrap: wrap;
          gap: 5px;

          .hash {
            display: flex;
            align-items: center;
            padding-inline: 20px;
            height: 36px;
            border-radius: 5px;
            background-color: $bg;
            margin-bottom: 5px;

            span.remove {
              margin-left: 10px;
              cursor: pointer;
            }
          }
        }
        .hashtags {
          display: flex;
          flex-wrap: wrap;
          gap: 5px;

          .hash {
            display: flex;
            align-items: center;
            padding-inline: 20px;
            height: 36px;
            border-radius: 5px;
            background-color: $bg;
            margin-bottom: 5px;

            span.remove {
              margin-left: 10px;
              cursor: pointer;
            }
          }
        }
        &.working_condition {
          @include media-max($s) {
            .select {
              width: 47.5%;
            }
            .input {
              width: 47.5%;
            }
            .com_chk {
              & + .decision {
                margin-left: -20px;
              }
            }
          }
        }
      }
      .personal_field {
        display: grid;
        gap: 20px;
        grid-template-columns: repeat(2, 1fr);
      
        @include media-max($s) {
          grid-template-columns: 1fr; 
        }
      
        .photo {
          margin-top: 27px;
      
          .title {
            display: flex;
            gap: 7px;
            font-size: 14px;
            font-weight: 500;
      
            .icon_pencil {
              display: inline-block;
              background: url("../../img/icon_pencil_black.png") no-repeat 0 0;
              height: 20px;
              width: 20px;
              background-size: contain;
            }
          }
      
          .icon_camera,
          .resume_image {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $bg;
            border: 1px solid #dfdfdf;
            height: 150px;
            width: 150px;
      
            .img_camera {
              height: 70px;
              width: 70px;
            }
          }
        }
      
        .input_field {
          display: grid;
          gap: 20px;
          grid-template-columns: repeat(2, 1fr);
      
          @include media-max($s) {
            grid-template-columns: 1fr; 
          }
      
          .com_input_sec {
            padding: 15px 20px;
            border-bottom: 1px solid #dfdfdf;
      
            .input {
              width: 100%;
              margin-top: 8px;
            }
      
            .input_add {
              width:  160px;
              margin-top: 8px;
            }
      

            .radio_wrapper {
              display: flex;
              gap: 30px;
              padding: 13px 0;
      
              .gender {
                width: auto;
                padding-left: 30px;
      
                &::before {
                  left: 25px;
                }
              }
            }
          }
      
          .error-message {
            color: red;
            font-size: 12px;
            margin-top: 5px;
          }
        }
      }

      .education_wrapper {
        .education_field {
          padding-block: 15px;
          @include flex(y);
          flex-wrap: wrap;
          gap: 10px;
          .input {
            margin-left: 0;
            width: 169px;
            @include media-max($s) {
              width: 100%;
            }
          }
          .university {
            @include media-max($s) {
              width: 100%;
            }
            @include flex(y);
            gap: 10px;
          }
          .date {
            @include flex(y);
            gap: 10px;
            .input {
              width: 110px;
            }
            .status {
              width: 100px;
            }
            @include media-max($s) {
              &,
              & .input {
                width: 100%;
              }
            }
          }
        }
      }
      .certification_wrapper {
        .certification_field {
          padding: 15px 0;
          @include flex(y);
          flex-wrap: wrap;
          gap: 10px;
          .input_wrapper {
            @include flex(y);
            gap: 10px;
            flex-wrap: wrap;
            width: 100%;
            .input {
              margin-left: 0;
              width: 170px;
            }
            .department {
              @include flex(y);
              gap: 10px;
              .input {
                width: 130px;
              }
            }
            .time {
              @include flex(y);
              gap: 10px;
              .input {
                width: 110px;
                @include media-min($s) {
                  &:last-child {
                    width: 100px;
                  }
                }
              }
            }
            .logo {
              height: 45px;
              border: 1px solid #ccc;
              border-radius: 5px;
              padding: 12px 15px;
              text-align: center;
              img {
                max-height: 100%;
                max-width: 100%;
              }
            }
            @include media-max($s) {
              .input,
              .department,
              .time,
              .logo,
              .department .input,
              .time .input {
                width: 100%;
              }
            }
          }
          .textarea {
            max-width: 800px;
            min-height: 90px;
          }
          .desc {
            @include media-max($m) {
              font-size: 13px;
            }
          }
        }
      }
      .certificate_wrapper {
        .certificate_field {
          padding: 15px 0;
          @include flex(y);
          gap: 10px;
          .input {
            margin-left: 0;
          }
          .wrapper {
            margin-left: 0;
            width: 46%;
            &.icon_search {
              position: relative;
              &::after {
                content: "";
                display: inline-block;
                width: 20px;
                height: 20px;
                background: url("../../img/icon_search.png") no-repeat 0 0;
                background-size: contain;
                position: absolute;
                top: 10px;
                right: 10px;
              }
            }
          }
          .s {
            width: 100%;
            @include flex(y);
            gap: 10px;
          }
          .btn {
            margin-left: 0;
          }
          @include media-max($s) {
            @include flex(column);
            .wrapper {
              width: 100%;
            }
          }
        }
      }
      .education_detail_field {
        padding: 15px 0;
        position: relative;
        .input_wrapper {
          @include flex(y);
          gap: 10px;
          padding-bottom: 10px;
          .input_box {
            width: 100%;
            @include flex(y);
            gap: 10px;
          }
          @include media-max($s) {
            flex-wrap: wrap;
            .input {
              width: 100%;
            }
          }
          .input {
            margin-left: 0;
          }
          .date {
            @include flex(y);
            gap: 10px;
            @include media-max($s) {
              width: 100%;
            }
          }
        }
        .description {
          width: calc(100% - 31px);
        }
        .btn_delete {
          @include media-max($s) {
            position: absolute;
            right: 0;
            bottom: 28px;
          }
        }
      }
      .textarea_field {
        padding: 15px 0;
        &:last-child {
          border: none;
        }
        .input {
          min-height: 98px;
          @include media-max($s) {
            min-height: 150px;
          }
        }
        .textarea_input {
          min-height: 450px;
          @include media-max($s) {
            min-height: 300px;
          }
        }
        .count {
          font-size: 12px;
          color: #c4c4c4;
          margin-top: 10px;
          text-align: end;
        }
      }
      .link_wrapper {
        padding: 15px 0;
        .link_field {
          @include flex(y);
          padding-bottom: 10px;
          &:nth-last-child(3) {
            border-bottom: 1px solid #dfdfdf;
            padding-bottom: 15px;
          }
        }
        .com_btn {
          margin-top: 15px;
        }
      }
      .button_wrapper {
        @include flex(all);
        gap: 10px;
        margin-block: 40px;
        .com_btn {
          min-width: 150px;
          height: 40px;
        }
        @include media-max($s) {
          margin-block: 30px;
          .com_btn {
            flex: 1;
          }
        }
      }
    }
    .com_btn {
      &.xs {
        min-width: 120px;
      }
      &.s {
        min-width: 60px;
      }
    }
    .with_delete {
      @include media-max($s) {
        width: calc(100% - 30px) !important;
      }
    }
  }
}

@import "../common/variables";
//!로그인,회원가입 공통
.p_adm_login {
  padding-block: 150px 200px;
  height: 100vh;
  background: #24292e;
  .box {
    width: 598px;
    padding: 50px 77px 60px 78px;
    border-radius: 10px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    border: 3px solid #fff;
    background: #24292e;
    margin-inline: auto;
    .site_name {
      color: $point;
      font-size: 20px;
      font-weight: bold;
      text-align: center;
      margin-bottom: 15px;
    }
    .title {
      margin-bottom: 30px;
      color: #fff;
      font-size: 45px;
      font-weight: bold;
      line-height: 1.19;
      letter-spacing: -0.65px;
      text-align: center;
    }
    .txt {
      margin-top: -20px;
      margin-bottom: 25px;
      font-size: 15px;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: center;
      color: $gray;
    }
    .check {
      margin-top: 15px;
      .adm_com_chk {
        & + label {
          color: #fff;
        }
      }
    }
    .sec_input {
      & + .sec_input {
        margin-top: 5px;
      }
    }
  }
  .error_txt {
    text-align: center;
    color: $red;
    font-size: 13px;
    line-height: 1.54;
    letter-spacing: -0.33px;
  }
  .txt_box {
    margin-top: 25px;
    padding: 25px $pd-inline;
    border-radius: 5px;
    border: solid 1px $c-border;
    background-color: $white;
    text-align: center;
    line-height: 1.6;
  }
  .adm_com_btn_wrap {
    margin-top: 25px;
    @include flex(x);
    flex-wrap: wrap;
    width: 100%;
    gap: 10px;
    .adm_com_btn {
      flex: 1;
      width: 100%;
      margin-left: 0;
    }
  }
  .input,
  .select {
    height: 50px;
    line-height: 50px;
    width: 100%;
  }
  .check {
    label {
      margin-left: 5px;
    }
  }
}

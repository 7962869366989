#root {
  height: 100vh;
}
.adm_com_container {
  padding: 90px 20px 0px 220px;
  min-width: $min-width;
  background: #fff;
  min-height: 100vh;
  @include media-min($full) {
    padding: 110px 40px 0px 240px;
  }
}
.adm_com_bg {
  background: $bg;
}
//!header
.adm_head {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 10;
  .logo {
    .img {
      min-width: 60px;
      width: 60px;
      height: 60px;
    }
  }
  &.isHideGnb {
    display: none;
    // .gnb_wrap {
    //   .adm_com_center_wrap {
    //     .logo {
    //       display: block;
    //     }
    //   }
    // }
    // .gnb_wrap {
    //   padding: 0px 63px 0 18px;
    //   .adm_com_center_wrap {
    //     padding-top: 16px;
    //     padding-bottom: 13px;
    //   }
    // }
  }
  .gnb_wrap {
    padding: 0px 0 0 190px;
    @include media-min($full) {
      padding-left: 210px;
    }
    background-color: #2d2d2d;
    // overflow: auto;
    &::-webkit-scrollbar-thumb {
      background-color: rgba(255, 255, 255, 0.15);
    }
    .adm_com_center_wrap {
      @include flex(between);
      width: 100%;
      padding: 20px 20px 21px;
      @include media-min($full) {
        padding-right: 40px;
      }
      min-width: $min-width;
    }
    .gnb_list {
      @include flex(y);
      margin-left: 0px;
      gap: 20px;
      .link {
        padding: 6px 10px;
        font-size: 16px;
        font-weight: bold;
        line-height: 1.19;
        letter-spacing: -0.4px;
        text-align: center;
        color: #fff;
        &.active {
          background: $point;
          color: $white;
          border-radius: 30px;
        }
      }
    }
    .menu_list {
      @include flex(y);
      gap: 10px;
      margin-left: 25px;
      .link {
        padding: 6px 10px;
        text-align: center;
        color: rgba(255, 255, 255, 0.8);
      }
    }
  }
  //left sub gnb
  .gnb_sub_wrap {
    width: 200px;
    height: 100vh;
    position: absolute;
    left: 0;
    top: 0;
    background: $fc-default;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    .logo {
      width: 100%;
      text-align: center;
      padding-block: 24px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      color: #fff;
      h1 {
        font-size: 22px;
        font-weight: bold;
      }
    }
    .list {
      overflow: auto;
      height: calc(100vh - 70px);
      margin-block: 10px;
      padding-bottom: 30px;
      &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.5);
      }
      .link {
        @include flex(y);
        color: $white;
        font-size: 15px;
        line-height: 1.2;
        letter-spacing: -0.38px;
        padding: 7px 20px;
        &.depth1 {
          &.lnb_active {
            background: $point;
            font-weight: bold;
          }
        }
        &.depth2 {
          font-size: 14px;
          font-weight: normal;
          color: rgba(255, 255, 255, 0.4);
          &::before {
            content: "-";
            margin-right: 5px;
          }
          &.last {
            margin-bottom: 10px;
          }
          &.lnb_active {
            color: rgba(255, 255, 255, 0.4);
            &.active {
              color: #fff;
            }
          }
          & + .depth1 {
            margin-top: 5px;
          }
        }
      }
    }
  }
}

//body contents layout
.adm_com_layout {
  .section {
    flex: 1;
  }
  &.col2 {
    @include flex(x);
    gap: 40px;
  }
}

.bottom_line {
  border-bottom: 1px solid #dfdfdf;
}

.adm_table_btn {
  padding: 10px;
}
.flex-end {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
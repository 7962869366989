// container
.adm_com_pg_container {
  @include flex(y);
  gap: $pd-inline;
  flex-wrap: wrap;
}
.adm_com_pg_content {
  .end {
    justify-content: flex-end;
    margin-left: auto;
  }
  .board_tit {
    margin-top: 20px;
    font-weight: bold;
    @include dot(5px, 2px, 3px, $fc-default);
  }
  .sub_tit {
    font-size: 16px;
    font-weight: bold;
    margin: 20px 0 14px;
    display: flex;
    justify-content: space-between
  }
}
// 파일첨부
.adm_com_input_upload {
  @include flex(y);
  align-items: flex-end;
  .img_box {
    position: relative;
    max-width: 150px;
    max-height: 150px;
    overflow: hidden;
    object-fit: contain;
    margin-right: 10px;
  }
  .input_file {
    display: none;
  }
  .btn_attach {
    margin-right: 10px;
  }
  .btn_img_attach {
    width: 100px;
    height: 100px;
    background-color: #ccc;
    margin-left: 0;
    font-weight: bold;
  }
  .btn_upload {
    display: block;
    margin-right: 10px;
    margin-left: 0;
  }
  .file {
    @include flex(y);
    align-items: flex-end;
    font-size: 13px;
    line-height: 1.15;
  }
  .btn_del_file {
    position: absolute;
    top: 10px;
    right: 10px;
    background: url("../../../assets/img/icon_close.png") no-repeat 0 0;
    background-size: 12px;
    width: 12px;
    height: 12px;
  }
  .btn_down {
    margin-left: 10px;
    height: 18px;
  }
}
// comment
.adm_com_comment_wrap {
  margin-top: 10px;
  .comment_list {
    .item {
      align-self: stretch;
      padding: 10px 10px 15px 4px;
      border-bottom: 1px solid $c-border;
      .info {
        @include flex(y);
        background: none;
        padding: 0;
        color: $gray;
        .name {
          font-size: 13px;
          font-weight: normal;
        }
        .date {
          font-size: 12px;
        }
        .dot {
          @include dot(2px, 2px, 5px, $gray);
        }
      }
      .txt {
        margin-top: 7px;
        font-size: 14px;
      }
      .adm_com_btn_wrap {
        margin-left: auto;
        @include flex(s);
        gap: 10px;
      }
    }
    & + .comment_input {
      padding-top: 10px;
    }
  }
  .comment_input {
    @include flex(between);
    align-items: center;
    .textarea {
      min-height: 60px;
      height: 60px;
      line-height: 1.2;
      margin-bottom: 0;
    }
    .adm_com_btn {
      min-width: 60px;
      width: 60px;
      font-size: 14px;
      font-weight: 500;
    }
  }
  &.type_box {
    .comment_list {
      @include flex(s);
      flex-wrap: wrap;
      gap: 10px;
      .item {
        width: calc((100% / 3) - 7px);
        padding: 17px 15px;
        border-radius: 5px;
        border: solid 1px $c-border;
        background-color: #f8f9fa;
      }
    }
    .comment_input {
      padding-top: 20px;
    }
  }
  &.type_board {
    .comment_list {
      max-height: 252px;
      overflow: auto;
      margin-top: 10px;
      padding: 10px 10px 10px 15px;
      border-radius: 5px;
      border: solid 1px $c-border;
      background-color: #f8f9fa;
    }
    &.short {
      .comment_list {
        padding: 5px 5px;
        max-height: 100px;
      }
    }
  }
  .select {
    width: 150px;
    margin-bottom: 10px;
  }
  .textarea {
    margin-bottom: 10px;
  }
}
// calendar
.adm_com_calendar_wrap {
  padding-block: 5px 13px;
  &.has_option {
    @include flex(right);
    margin: 10px 0 0 0;
    position: relative;
    .select {
      width: auto;
    }
    .option {
      width: 20%;
    }
    .year {
      @include flex(center);
      width: 60%;
    }
    .adm_com_count_wrapper {
      width: 20%;
    }
  }
  .year {
    @include flex(all);
    .btn {
      margin-inline: 2.5px;
    }
    .btn_first {
      transform: rotate(180deg);
      @include addImg(icon_arrow_right_end, 18, 18, contain);
    }
    .btn_prev {
      transform: rotate(180deg);
      @include addImg(icon_arrow_right, 18, 18, contain);
    }
    .btn_next {
      @include addImg(icon_arrow_right, 18, 18, contain);
    }
    .btn_last {
      @include addImg(icon_arrow_right_end, 18, 18, contain);
    }
    .btn_txt {
      margin: 0 10px;
      font-size: 20px;
      font-weight: bold;
      line-height: 1.2;
      letter-spacing: -0.5px;
      color: $fc-default;
    }
  }
  .month {
    @include flex(all);
    margin-top: 20px;
  }
  .tab {
    position: relative;
  }
  .icon {
    @include inflex(x);
    position: absolute;
    left: 13px;
    top: -12px;
    width: 56px;
    height: 20px;
    padding: 4px 5px 3px;
    border-radius: 10px;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
    background-color: $white;
    font-size: 11px;
    font-weight: normal;
    line-height: 1.18;
    color: $gray;
    text-align: center;
  }
}
// width: 100% bar
.adm_com_divider {
  width: 100%;
  height: 1px;
  background: $c-border;
  margin: 10px 0;
  &.type_inner {
    margin: 15px 25px 10px 25px;
    width: calc(100% - 50px);
  }
  & + .adm_com_board_list__top {
    margin-bottom: 10px;
    .tit {
      margin-top: 10px;
    }
    .year + .month {
      .adm_com_tab {
        margin-top: 0;
      }
    }
  }
}
// text icon
.adm_com_icon {
  width: 57px;
  height: 24px;
  padding: 4px 2px;
  border-radius: 3px;
  font-size: 13px;
  text-align: center;
  font-weight: bold;
  line-height: 1.15;
  letter-spacing: -0.33px;
  &.blue {
    border: solid 1px #cddcee;
    background-color: #eaf2fc;
    color: $blue;
  }
  &.green {
    border: solid 1px #cce8cc;
    background-color: #edf8ed;
    color: $green;
  }
  &.red {
    border: solid 1px #ebc7c7;
    background-color: $lightRed;
    color: $red;
  }
  &.gray {
    border: solid 1px $c-border;
    background-color: $bg;
    color: $gray;
  }
  &.darkGray {
    border: solid 1px $gray;
    background-color: $gray;
    color: $white;
  }
  & + .adm_com_icon {
    margin-left: 5px;
  }
  & + .name {
    margin-left: 10px;
  }
}

@import '../common/variables';

.com_mentoring {
  .com_center_wrap {
    @include media-max($xl) {
      padding-inline: $pd-inline;
    }

    .com_card_wrap {
      @include media-max($xl) {
        margin-left: -20px;
        margin-top: 20px;
      }

      @include media-max($m) {
        margin-top: 15px;
      }

      .com_card_item {
        position: relative;
        border-radius: 20px;
        display: block;
        @include media-max($xl) {
          min-width: calc((100% / 2) - 20px);
          max-width: calc(50% - 20px);
          margin-left: 20px;
          margin-bottom: 30px;
        }
        .thumb {
          @include thumb(373px,240px);
          max-height: 240px;
          @include media-max($xl) {
            @include thumb(100%,100%);
            max-height: 190px;
          }
          @include media-max($m) {
            @include thumb(100%,100%);
            max-height: 170px;
          }
          @include media-max($s) {
            max-height: 150px;
          }
          @include media-max($xs) {
            max-height: 90px;
          }
          .price {
            position: absolute;
            top: 15px;
            left: 10px;
            padding: 4px 10px;
            color: $white;
            background-color: $point;
            font-size: 12px;
            font-weight: 800;
            border-radius: 13px;
          }
        }

        .info {
          .icon_wrap {
            @include flex(y);
            justify-content: left;
            flex-wrap: wrap;
            gap: 5px;

            @include media-max($m) {
              margin-bottom: 10px;
            }

            @include media-max($m) {
              .icon_line_blue:not(:first-child) {
                display: none;
              }
            }
          }

          .tit {
            font-size: 20px;
            font-weight: 600;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }

          @include media-max($m) {
            margin-top: 15px;

            .tit {
              font-size: 14px;
            }

            .sm_remove {
              display: none;
            }

            .info_s {
              flex-wrap: wrap;
              gap: 4px;
            }
          }
        }
      }
    }
  }

  // list
  .sec_mentoring {
    padding: 30px 0 130px;

    @include media-max($m) {
      padding: 30px 0 50px;
    }
    .com_tab_wrap {
      @include media-max($xl) {
        margin: 25px 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
      .content_tab {
        width: calc(100% - 260px);
        @include media-max($xl) {
          width: 100%;
          overflow: auto;
        }
        .tab {
          min-width: auto;
          padding: 5px;
          height: 45px;
        }
      }
      .input_box {
        @include media-max($xl) {
          width: 100%;
          margin-top: 15px;
        }
        .input {
          @include media-max($xl) {
            width: 100%;
          }
        }
      }
    }
  }

  // intro
  .sec_intro {
    padding: 30px 0 143px;

    @include media-max($xl) {
      padding: 30px 0 100px;
    }

    .intro_tit {
      padding: 88px 0 53px;
      font-size: 40px;
      font-weight: bold;
      line-height: 1.05;
      letter-spacing: normal;
      text-align: center;
      color: #000;

      @include media-max($xl) {
        padding: 20px 0 23px;
        font-size: 20px;
      }
    }

    .intro_list {
      @include flex(all);
      @include flex-wrap(wrap);
      margin-bottom: 50px;

      @include media-max($xl) {
        margin-left: -16px;
        margin-bottom: 20px;
      }

      .intro_item {
        @include flex(all);
        padding: 0 26px;
        margin: 0 13px;
        flex-direction: column;
        width: calc((100% / 3) - 27px);
        height: 310px;
        margin-bottom: 30px;
        border-radius: 20px;
        box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.16);
        border: solid 1px #dedede;
        background-color: #fff;
        text-align: center;

        @include media-max($xl) {
          max-width: calc((100%/2) - 16px);
          min-width: calc((100%/2) - 16px);
          height: 250px;
          margin: 0 0 16px 16px;
          border-radius: 10px;
        }

        &:last-child {
          @include media-max($xl) {
            max-width: 100%;
            flex: 1;
          }
        }

        .img {
          width: 100px;
          margin-bottom: 17px;

          @include media-max($xl) {
            width: 63px;
          }
        }

        .item_tit {
          font-size: 24px;
          font-weight: bold;
          line-height: 1.21;
          letter-spacing: normal;
          text-align: center;
          color: #000;

          @include media-max($xl) {
            font-size: 14px;
          }
        }

        .desc {
          margin-top: 17px;
          font-size: 18px;
          line-height: 1.39;
          letter-spacing: normal;

          @include media-max($xl) {
            font-size: 11px;
          }
        }
      }
    }

    .process_list {
      @include flex(x);
      justify-content: space-between;
      margin-bottom: 200px;

      @include media-max($xl) {
        flex-direction: column;
        margin-bottom: 20px;
      }

      .process_item {
        position: relative;
        width: 150px;

        @include media-max($xl) {
          width: 100%;
          margin-bottom: 10px;
        }

        &::after {
          content: '';
          position: absolute;
          left: 154px;
          top: 74px;
          display: inline-block;
          width: 35%;
          height: 1px;
          background-color: $point;

          @include media-max($xl) {
            width: 1px;
            height: 10px;
            left: 50%;
            top: auto;
          }
        }

        &:last-child {
          &::after {
            display: none;
          }
        }

        .circle {
          // flex-direction: column;
          width: 150px;
          height: 150px;
          padding-top: 36px;
          border: solid 1px $point;
          border-radius: 100%;
          background-color: #f5f7ff;
          text-align: center;

          @include media-max($xl) {
            // @include flex(s);
            // flex-direction: row;
            overflow: hidden;
            border-radius: 10px;
            width: 100%;
            height: auto;
            min-height: 50px;
            // max-height: 90px;
            padding: 14px 20px;
          }

          .txt_box {
            @include media-max($xl) {
              float: left;
              width: calc(100% - 30px);
              text-align: left;
            }
          }

          .num {
            font-size: 30px;
            font-weight: bold;
            line-height: 0.8;
            color: $point;

            @include media-max($xl) {
              float: left;
              margin-top: 3px;
              font-size: 16px;
              margin-right: 15px;
            }
          }

          .desc {
            margin-top: 20px;
            font-size: 18px;
            line-height: 1.33;
            letter-spacing: normal;
            color: #000;

            @include media-max($xl) {
              margin-top: 0;
              font-size: 14px;
              font-weight: bold;
            }
          }
        }

        .item_desc {
          position: relative;
          top: 62px;
          font-size: 14px;
          line-height: 1.43;
          letter-spacing: -0.35px;
          text-align: left;
          color: #000;
          padding-left: 10px;

          &::before {
            content: '-';
            display: inline-block;
            position: absolute;
            left: 0;
          }

          &+.item_desc {
            margin-top: 10px;

            @include media-max($xl) {
              margin-top: 4px;
            }
          }

          @include media-max($xl) {
            top: auto;
            margin-top: 8px;
            width: 100%;
            font-size: 13px;
            text-align: left;
            color: #666;
          }
        }
      }
    }

    .com_btn_wrap {
      @include media-max($xl) {
        padding: 0 20px;
        margin-top: 0;
      }

      .com_btn {
        &.line {
          background: transparent;
        }
      }
    }
  }
}

.review-list {
  max-height: 437px;
  margin: 10px 20px;
  padding: 20px 10px;
  border-radius: 5px;

  border: solid 1px #e4e5ed;
  background-color: #fff;

  overflow: auto;
}

.review-content {
  font-size: 1rem;
  font-weight: 500;

  margin-top: 20px;
  margin-inline: 10px;
}

.review-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}


.star-component {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-block: 20px;

}

.rating-text-title {
  font-size: 1.2rem;
  font-weight: 600;
}

.rating-text {
  font-size: 2rem;
  font-weight: 600;
}

.star-container {
  height: 20px;
}

.split-star {

  font-size: 2rem;
  display: inline-block;
  width: 1em;
  overflow: hidden;
  color: #ABABAB;
  transition: color .2s ease-out;
  &:hover {
    cursor: pointer;
  }
}
.split-star .half {
  display: inline-block;
  width: 50%;
  text-align: center;
  overflow: hidden;
  transform: scaleX(1);
}
.split-star .flip {
  transform: scaleX(-1);
}
.split-star .is-selected {
  color: #3366ff;
}

@import "../common/variables";

.p_community {
  .sec_community {
    padding: 30px 0 130px;
    @include media-max($m) {
      padding: 30px 0 50px;
    }
    .com_center_wrap {
      @include media-max($xl) {
        padding-inline: $pd-inline;
      }
      .com_tab_wrap {
        .content_tab {
          gap: 5px;
          @include flex(y);
          gap: 15px;
          width: 100%;
          & > .tab {
            min-width: 50px;
            @include media-max($xl) {
              display: none;
            }
          }
          .swiper_wrap {
            @include flex(y);
            gap: 5px;
            position: relative;
            &.category {
              width: 100%;
              height: 38px;
              display: none;
              @include media-max($xl) {
                display: block;
              }
              .swiper {
                width: calc(100% - 100px);
                text-align: center;
              }
              .swiper-slide {
                min-width: 40px;
                width: auto !important;
                padding: 0;
              }
              .swiper-button-prev {
                left: 0;
              }
              .swiper-button-next {
                right: 0;
              }
              .swiper-button-prev,
              .swiper-button-next {
                min-width: 38px;
                height: 38px;
                border-radius: 100%;
                background: $white;
                color: $point;
                box-shadow: 0 0 20px 0 rgba(0, 53, 255, 0.3);
                &::after {
                  font-size: 14px;
                  font-weight: bold;
                }
                &.swiper-button-disabled {
                  color: #999;
                  opacity: 1;
                  box-shadow: 0 0 20px 0 rgba(102, 102, 102, 0.2);
                }
              }
            }
          }
        }
      }
      .career {
        @include flex(all);
        margin-top: 37px;
        min-height: 100px;
        border: 1px solid #dfdfdf;
        border-radius: 20px;
        padding: 30px;
        @include media-max($m) {
          flex-direction: column;
          gap: 10px;
          padding: 20px;
          border-radius: 5px;
          margin-top: 25px;
          & div.input_box {
            margin: 0;
            width: 100%;
          }
        }
        .input_box {
          width: 490px;
          margin-left: auto;
          margin-right: 10px;
          @include flex(y);
          &.icon_search {
            &::after {
              content: "";
              display: inline-block;
              width: 20px;
              height: 20px;
              background: url("../../img/icon_search.png") no-repeat 0 0;
              background-size: contain;
              margin-left: -35px;
            }
          }
          .input {
            border-radius: 5px;
            padding: 0 40px 0 15px;
          }
        }
        .com_btn {
          background-color: $point;
          border-radius: 40px;
          color: $white;
          font-size: 14px;
          padding: 12px 20px;
          height: 40px;
          @include media-max($m) {
            padding: 12px 10px;
          }
        }
        .icon_pencil {
          position: relative;
          width: 300px;
          padding-right: 45px;
          margin-right: 10px;
          text-align: center;
          @include media-max($m) {
            width: 100%;
          }
          &::after {
            content: "";
            display: inline-block;
            width: 30px;
            height: 30px;
            background: url("../../img/icon_pencil.png") no-repeat 0 0;
            background-size: contain;
            position: absolute;
            top: 8px;
            right: 16px;
          }
        }
        .btn_category {
          min-width: 100px;
          padding: 12px 25px;
          @include media-max($m) {
            min-width: 100%;
          }
        }
      }
      .community_list {
        margin: 23px 0 40px;
        // border-radius: 20px;
        // background-color: $white;
        // border: 1px solid #dfdfdf;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        @include media-max($m) {
          gap: 10px;
        }
        .com_community_card {
          width: calc((100% / 2) - 10px);
          background-color: #fff;
          border-radius: 20px;
          &:not(:last-child) {
            border-bottom: none;
          }
          @include media-max($m) {
            width: 100%;
          }
        }
      }
    }
  }
}

// Community Write component
.p_write {
  .com_tag_list {
    .com_btn {
      &.active {
        color: $point;
        background-color: $white;
        border: 1px solid $point;
      }
      cursor: pointer;
    }
  }
  .com_attach_section {
    &.type_community {
      @include media-max($m) {
        @include flex(between);
        padding: 15px 15px 10px;
        border: 1px solid #dfdfdf;
        border-radius: 5px;
      }
    }
  }
}

@import "../common/variables";

.p_insight {
  .sec_insight {
    padding: 30px 0 130px;
    @include media-max($m) {
      padding: 30px 0 50px;
    }
    .com_center_wrap {
      position: relative;
      @include media-max($xl) {
        padding-inline: $pd-inline;
      }
      .com_sub_tit_wrap {
        .com_btn_wrap {
          @include flex(y);
          @include flex-wrap(wrap);
          margin-top: 0;
          @include media-max($m) {
            width: 100%;
            margin-top: 16px;
          }
          .com_btn {
            min-width: 88px;
            font-size: 14px;
            font-weight: 500;
            padding: 0 20px;
            width: auto;
            flex: auto;
            @include media-max($m) {
              height: 50px;
              flex: 1;
            }
          }
          .btn_all_list {
            @include media-max($m) {
              min-width: 100%;
              margin-left: 0;
              margin-top: 10px;
              justify-content: space-between;
            }
          }
        }
      }
      .com_tab_wrap {
        position: relative;
        .btn_filter {
          padding: 4px 12px;
          border: 1px solid #1c1c1c;
          background-color: $white;
          border-radius: 30px;
          height: 37px;
          &::before {
            content: "";
            background-image: url("../../img/icon_filter.png");
            background-repeat: no-repeat;
            background-size: contain;
            background-position: right;
            display: inline-block;
            width: 17px;
            height: 11px;
            margin-right: 8px;
          }
          @include media-max($m) {
            height: 25px;
            position: absolute;
            right: 0;
            top: 54px;
            &::before {
              display: none;
            }
          }
        }
      }
      .list_wrap {
        &.m-80 {
          margin-top: 80px;
        }
        .tit {
          font-size: 24px;
          font-weight: 600;
          @include media-max($m) {
            font-weight: 800;
            font-size: 15px;
          }
        }
        .com_card_wrap {
          @include media-max($xl) {
            margin-left: -20px;
            margin-top: 20px;
          }
          @include media-max($m) {
            margin-top: 15px;
          }
          .com_card_item {
            position: relative;
            border-radius: 20px;
            @include media-max($xl) {
              min-width: calc((100% / 2) - 20px);
              max-width: calc(50% - 20px);
              margin-left: 20px;
              margin-bottom: 30px;
            }
            .thumb {
              @include thumb(373px,275px);
              max-height: 275px;
              @include media-max($xl) {
                @include thumb(100%,auto);
                max-height:none;
                aspect-ratio: 38/28;
              }
              // @include media-max($m) {
              //   @include thumb(100%,100%);
              //   max-height: 170px;
              // }
              // @include media-max($s) {
              //   max-height: 150px;
              // }
              // @include media-max($xs) {
              //   max-height: 90px;
              // }
              .price {
                position: absolute;
                top: 15px;
                left: 10px;
                padding: 4px 10px;
                color: $white;
                background-color: $point;
                font-size: 12px;
                font-weight: 800;
                border-radius: 13px;
              }
            }
            .price {
              position: absolute;
              top: 15px;
              left: 10px;
              padding: 4px 10px;
              color: $white;
              background-color: $point;
              font-size: 12px;
              font-weight: 800;
              border-radius: 13px;
            }
            .info {
              .icon_wrap {
                @include flex(y);
                justify-content: left;
                flex-wrap: wrap;
                gap: 5px;
                @include media-max($m) {
                  margin-bottom: 10px;
                }
                .icon_wrapper {
                  display: none;
                }
                @include media-max($m) {
                  .icon_line_blue:not(:first-child) {
                    display: none;
                  }
                  .icon_wrapper {
                    @include flex(y);
                    gap: 2px;
                    width: 10px;
                    padding: 5px 10px;
                    height: 24px;
                    width: 30px;
                    border: 1px solid $point;
                    border-radius: 15px;
                    .dot {
                      position: relative;
                      display: inline-block;
                      height: 1px;
                      width: 2px;
                      &::after {
                        position: absolute;
                        margin: 0;
                        left: 0;
                        border-radius: 50%;
                        background-color: $point;
                      }
                    }
                  }
                }
                .icon_line_wrap {
                  font-size: 16px;
                }
              }
              .title {
                font-size: 20px;
                font-weight: 600;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
              }
              @include media-max($m) {
                margin-top: 15px;
                .title {
                  font-size: 14px;
                }
                .sm_remove {
                  display: none;
                }
                .info_s {
                  flex-wrap: wrap;
                  gap: 4px;
                }
              }
            }
          }
        }
      }
    }
  }
  .list_wrap {
    & + .list_wrap {
      margin-top: 80px;
      @include media-max($m) {
        margin-top: 26px;
      }
    }
  }
}

// Detail
.p_detail {
  .com_center_wrap {
    @include media-max($m) {
      padding-bottom: 80px;
    }
    .sec_detail {
      .title_wrap,
      .description_wrap {
        @include media-max($m) {
          padding: 20px 20px;
        }
        .heading_wrap {
          .header {
            .price {
              @include media-max($m) {
                height: 23px;
                font-size: 11px;
              }
            }
          }
        }
      }
      .comment_box_wrap {
        .comment_wrap,
        .comment_input_box {
          @include media-max($m) {
            padding: 15px 20px;
          }
          &.reply {
            &::before {
              @include media-max($m) {
                top: 21px;
              }
            }
          }
          .user {
            @include media-max($m) {
              margin-bottom: 7px;
            }
          }
          .username,
          .desc {
            @include media-max($m) {
              font-size: 13px;
            }
          }
        }
        .eye {
          &::before {
            background: url("../../img/icon_eye.png") no-repeat 0 0;
            background-size: contain;
          }
        }
        .like {
          &::before {
            background: url("../../img/icon_like_black.png") no-repeat 0 0;
            background-size: contain;
          }
        }
        .liked {
          &::before {
            background: url("../../img/icon_like_on.png") no-repeat 0 0;
            background-size: contain;
          }
        }
        .comment {
          &::before {
            background: url("../../img/icon_comment.png") no-repeat 0 0;
            background-size: contain;
          }
        }
      }
    }
  }
}

//^board style
//!list
.adm_com_board_list {
  margin-top: 10px;
  margin-bottom: 10px;
  border-top: 1px solid $c-border;
  border-bottom: 1px solid $c-border;
  &__top {
    margin-block: 20px 13px;
    background: rgba(0, 0, 0, 0.01);
    border: 1px solid $c-border;
    padding: 10px 20px;
    border-radius: 10px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.05);
    @include flex(column);
    gap: 10px;
    .select {
      width: 150px;
      min-width: 150px;
      height: 40px;
      & + .adm_com_tab {
        margin-left: 10px;
      }
    }
    .tit {
      margin: 0 10px;
      font-weight: bold;
      white-space: nowrap;
      width: 70px;
      min-width: 70px;
    }
    .input {
      &.name {
        width: 300px;
      }
    }
    .adm_com_btn {
      height: 40px;
    }
    .adm_com_date_select + .adm_com_input_wrap,
    .adm_com_input_wrap + .adm_com_input_wrap {
      margin-left: 0;
    }
    .adm_com_datepicker {
      width: 150px;
      min-width: 150px;
    }
  }
  :is(th, td) {
    border-top: 1px solid $c-border;
    border-left: 1px solid $c-border;
    text-align: center;
    font-size: 14px;
    letter-spacing: normal;
  }

  tr.selected {
    td {
      background-color: $lightOrange;
    }
  }
  th,
  td {
    &:is(:first-child) {
      border-left: none;
    }
    &.border_left {
      border-left: 1px solid $c-border;
    }
  }
  th {
    color: $black;
    font-weight: bold;
    padding: 10px 5px;
    background-color: #f0f1f3;
  }
  td {
    position: relative;
    padding: 8px 5px;
    .input {
      &.ws {
        width: 150px;
      }
    }
    &.has_btn_edit {
      padding-inline: 33px;
    }
    .adm_com_btn {
      margin-left: 20px;
    }
    //^td text color
    &.td_ {
      &red {
        color: $red;
        .txt,
        .name {
          color: $red;
        }
      }
      &point {
        color: $blue;
        .txt,
        .name {
          color: $blue;
        }
      }
      &green {
        color: $green;
        .txt,
        .name {
          color: $green;
        }
      }
    }
    //^ td background color
    &.highlight {
      background-color: $lightOrange;
    }
    &.bg_col {
      background-color: $bg-row;
    }
    &.td_left {
      text-align: left;
      padding: 0 15px;
    }
    &.td_right {
      text-align: right;
      padding: 0 15px;
    }
    &.bold {
      font-weight: bold;
    }
    .memo {
      @include multi-ellipsis(1);
    }
    .link {
      font-size: 14px;
    }
  }
  .adm_com_btn {
    min-width: auto;
    padding-inline: 14.5px;
  }
  &.type_row {
    th {
      text-align: left;
      padding-inline: 15px;
      word-break: break-all;
    }
    td {
      padding: 7px 10px;
      height: 50px;
      text-align: left;
      label + input[type="checkbox"] + label,
      label + input[type="radio"] + label {
        margin-left: 20px;
      }
      .input {
        width: auto;
      }
    }
  }
  &__scroll {
    overflow-y: auto;
    thead {
      position: sticky;
      top: -1px;
      z-index: 1;
    }
    & + .adm_com_btn_wrap {
      margin-top: 20px;
    }
  }
  & + .adm_com_btn_wrap {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .select {
    &.ws {
      width: 200px;
    }
  }
}

.adm_com_board_search {
  @include flex(y);
  gap: 10px;
  margin-bottom: 0;
  .adm_com_btn_wrap {
    .adm_com_btn {
      width: 50px;
      // border: 1px solid #999;
      color: $fc-default;
      & + .adm_com_btn {
        margin-left: 5px;
      }
      &.active {
        background: $gray;
        border: 1px solid #999;
        color: $white;
      }
    }
  }
  .option {
    .adm_com_btn {
      height: 40px;
      font-weight: bold;
      & + .adm_com_btn,
      & + .adm_com_check_box {
        margin-left: 5px;
      }
    }
    .adm_com_check_box {
      & + .adm_com_btn,
      & + .adm_com_check_box {
        margin-left: 5px;
      }
      label {
        font-weight: bold;
      }
    }
  }
  .adm_com_datepicker {
    height: 40px;
    line-height: 40px;
  }
  .search {
    @include flex(y);
    width: 100%;
  }
  .input {
    margin-bottom: 0;
  }
  .bar {
    @include divider(1px, 36px, 0px);
    &::after {
      vertical-align: middle;
    }
  }
  &.end {
    justify-content: flex-end;
  }
}

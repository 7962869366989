//^form style

//! 정보입력 폼 info input form
.adm_com_input_sec {
  margin-top: 2px;
  font-size: 14px;
  .icon_must {
    color: $point;
    margin-left: 4px;
  }
  .tit_field {
    margin-block: 20px 8px;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: left;
    color: #222;
    &.type_flex {
      @include flex(y);
    }
  }
  .input_field {
    margin-bottom: 14.5px;
    color: #666;
    input[type="text"],
    input[type="password"] {
      width: 100%;
    }
    > .tip_txt {
      margin-top: 14.5px;
    }
    .search_wrap {
      @include flex(between);
      .adm_com_btn {
        width: 40px;
        min-width: 40px;
        &.s {
          min-height: 40px;
        }
      }
      .input {
        width: calc(100% - 50px);
      }
    }
  }
  .adm_com_input_wrap {
    margin-bottom: 10px;
    .adm_com_btn {
      min-height: 45px;
      min-width: 110px;
    }
    &.flex_row {
      .select,
      .input {
        width: 170px;
      }
    }
  }
  .input_row {
    &.type_col {
      @include flex(y);
      .tit_field {
        width: 70px;
        flex: 1;
        margin-block: 10px 10px;
      }
      .input_field {
        margin-bottom: 5px;
        width: calc(100% - 80px);
      }
    }
  }
  &.type_col2 {
    @include flex(y);
    flex-wrap: wrap;
    margin-left: -20px;
    padding-top: 20px;
    .input_row {
      min-width: 140px;
      width: calc((100% / 2) - 20px);
      margin-left: 20px;
      margin-bottom: 20px;
      &.full {
        width: 100%;
      }
      .tit_field {
        margin-top: 0;
      }
      .input,
      .input_field {
        margin-bottom: 0;
      }
    }
  }
  .error_txt {
    display: block;
    text-align: left;
    color: $red;
    font-size: 13px;
    line-height: 1.54;
    letter-spacing: -0.33px;
  }

  //! input
  .input {
    // margin-bottom: 10px;
    &:disabled {
      background-color: #f8f8f8;
    }
    &.error {
      border-color: #ffa2a2;
    }
  }
  .input,
  .select {
    &.l {
      height: 50px;
      line-height: 50px;
    }
    &.s {
      height: 40px;
      line-height: 40px;
    }
    &.type_search {
      width: 300px;
    }
  }
}
//! datepicker
.adm_com_date_select {
  @include flex(y);
  .adm_com_datepicker {
    max-width: 120px;
    min-width: 120px;
    height: 40px;
    // padding-right: 13px;
    // background-image: url(../../../assets/img/icon_calendar.svg);
    // background-repeat: no-repeat;
    // background-position: right center;
    &[type="date"] {
      padding-right: 3px;
    }
  }
  .divider {
    display: inline-block;
    margin: 0 10px;
  }
  .adm_com_btn {
    min-width: 60px;
    margin-left: 5px;
    &.lightgray {
      font-weight: normal;
    }
  }
  & + .adm_com_input_wrap {
    margin-left: 20px;
  }
}
.adm_com_input_wrap {
  .sec_input {
    .select,
    .input {
      margin-bottom: 10px;
    }
    &.select_box {
      @include flex(between);
      align-items: center;
      margin-bottom: 10px;
      .select {
        width: 150px;
        margin-bottom: 0px;
      }
      .input {
        margin-bottom: 0;
      }
      .adm_com_btn_wrap {
        margin-left: auto;
        @include flex(s);
        gap: 10px;
        margin-top: 0;
      }
    }
  }
  .editor {
    min-height: 280px;
    padding: 10px;
    border: 1px solid $c-border;
    border-radius: 5px;
  }
  .adm_com_board_search {
    margin-bottom: 10px;
  }
  &.flex_row {
    @include flex(y);
  }
  &.flex_between {
    @include flex(between);
  }
  & + .adm_com_input_wrap {
    margin-left: 20px;
  }
}

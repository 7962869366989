@import '../common/variables';
//!company common
.com_company {
  .container {
    &.type_noBg {
      background: none;
      border: none;
      padding: 0;
      @include media-max($xl){
        padding: 20px 20px;
        background: $white;
      }
      .top_notice {
        padding: 30px 30px;
        margin-bottom: 10px;
        border-radius: 20px;
        border: 1px solid #dfdfdf;
        background: $white;
        @include media-max($xl){
          padding: 20px 20px;
          border-radius: 5px;
          margin-bottom: 20px;
        }
      }
      .tab_content {
        padding: 30px 30px;
        border-radius: 20px;
        border: 1px solid #dfdfdf;
        background: $white;
        @include media-max($xl){
          padding: 0;
          border-radius: 5px;
          border: none;
        }
      }
    }
  }
  .com_center_wrap {
    align-items: flex-start;
  }
  .com_btn_wrap {
    .com_btn {
      min-width: 110px;
    }
  }
  .aside {
    @include media-max($l){
      overflow: hidden;
      .lnb {
        &.active {
          top: 230px;
        }
      }
      .selected_menu {
        border-top: 1px solid #f5f4f4;
      }
    }
    .id_area {
      width: calc(100% + 32px);
      margin-left: -16px;
      margin-bottom: 12px;
      padding-bottom: 20px;
      border-bottom: 1px solid $c-border;
      @include media-max($l){
        margin-top: 10px;
        padding: 0;
        border-bottom: 0;
      }
    }
    .notice_txt {
      background: #f5f5f9;
      width: calc(100% + 32px);
      margin: 12px 0 -18px -16px;
      padding: 24px 24px;
      border-radius: 0 0 20px 20px;
      border-bottom: 0;
      font-size: 14px;
      line-height: 1.4;
      @include media-max($l){
        margin: 0;
        padding: 0 20px 0 0;
        background: none;
      }
    }
  }
  // tab cont
  .com_pg_tab {
    margin-bottom: 25px;
    @include media-max($m) {
      margin-bottom: 20px;
    }
    .tab {
      min-width: 180px;
      @include media-max($xl) {
        min-width: 130px;
      }
      @include media-max($m) {
        min-width: auto;
      }
    }
  }
  .com_paging {
    margin-top: 20px;
  }
  .pg_tit_wrap {
    @include flex(between);
    margin-bottom: 18px;
    @include media-max($m) {
      @include flex(column);
      align-items: flex-start;
      margin-bottom: 15px;
    }
    .com_pg_tit {
      margin-bottom: 0;
      font-size: 24px;
      font-weight: bold;
      line-height: 1.75;
      letter-spacing: normal;
      @include media-max($m) {
        font-size: 16px;
        font-weight: bold;
        line-height: 1.39;
      }
    }
  }
  // bar |
  .bar {
    @include divider(1px,12px, 10px);
    @include media-max($m) {
      &:after{
        @include divider(1px,12px, 8px);
        vertical-align: -2px;
      }
    }
  }
  // divider
  .divider {
    width: calc(100% + 60px);
    margin-left: -30px;
    margin-right: -30px;
    border-bottom: 1px solid $c-border;
    @include media-max($xl) {
      display: none;
    }
  }
  .dot {
    @include divider(2px,2px,5px);
    &::after {
      background: #999;
      vertical-align: middle;
    }
    &.blue {
      @include divider(4px,4px,5px);
      &::after {
        background-color: $point;
        vertical-align: 3px;
        border-radius: 100%;
      }
    }
  }
  // job, recruit item
  .card_list {
    @include flex(s);
    @include flex-wrap(wrap);
    margin-left: -20px;
    @include media-max($m) {
      flex-direction: column;
      margin-left: 0;
    }
    .item {
      position: relative;
      width: calc(100%/3 - 20px);
      padding: 15px 20px 20px 20px;
      border-radius: 10px;
      border: solid 1px #ccc;
      background-color: #fff;
      margin: 0 0 20px 20px;
      @include media-max($xl) {
        width: calc(100%/2 - 20px);
      }
      @include media-max($m) {
        width: 100%;
        margin-left: 0;
      }
      .status {
        display: block;
        font-size: 14px;
        font-weight: bold;
        line-height: 1.2;
        letter-spacing: normal;
        color: $point;
        margin-bottom: 8px;
        @include media-max($m) {
          font-size: 13px;
        }
      }
      .tit {
        font-size: 18px;
        font-weight: 600;
        line-height: 1.44;
        letter-spacing: normal;
        color: #2c2c2c;
        @include media-max($m) {
          font-size: 14px;
          margin-bottom: 5px;
        }
      }
      .info {
        @include flex(s);
        @include flex-wrap(nowrap);
        .info_s {
          @include flex(s);
          font-size: 13px;
          line-height: 1.69;
          letter-spacing: -0.33px;
          & + .info_s {
            margin-left: 18px;
            @include media-max($m) {
              margin-left: 10px;
            }
          }
          .name {
            color: #999;
          }
          .bar {
            @include divider(1px,10px,10px);
            @include media-max($m) {
              @include divider(1px,10px,5px);
            }
          }
        }
      }
      .com_btn_wrap {
        margin-top: 18px;
        .com_btn {
          border-radius: 50px;
          height: 30px;
          padding: 0 10px;
        }
      }
      .btn_del {
        position: absolute;
        top: 8px;
        right: 8px;
        background-color: #dfdfdf;
        color: $fc-default;
      }
      .region {
        margin-top: 6px;
        font-size: 14px;
        font-weight: bold;
        line-height: 1.43;
        color: #666;
      }
    }
    .com_btn {
      min-width: auto;
    }
  }
  .com_top_box {
    @include flex(y);
    padding-bottom: 30px;
    background: $white;
    @include media-max($xl) {
      flex-direction: column;
      align-items: flex-start;
      padding: 58px $pd-inline 20px;
      border-radius: 5px;
      border: solid 1px #dfdfdf;
    }
  }
  .detail_wrap {
    padding: 30px 0;
    background: $white;
    @include media-max($m) {
      padding: 20px;
      // border-radius: 5px;
      // border: solid 1px #dfdfdf;
    }
  }
  //attatch common
  .com_attach_section {
    @include flex(s);
    justify-content: flex-start;
    @include flex-wrap(wrap);
    border: none;
    padding-left: 0;
    @include media-max($m) {
      @include flex(between);
      padding: 16px 16px;
      border: 1px solid $c-border;
      border-radius: 5px;
    }
    .img_box {
      cursor: pointer;
      .img_upload {
        width: 120px;
        height: 80px;
      }
    }
  }
  //profile list
  .profile_list {
    border-top: 1px solid $c-border;
    @include media-max($m) {
      border-top: none;
    }
    .item {
      position: relative;
      @include flex(y);
      padding: 15px 25px;
      border-bottom: 1px solid $c-border;
      @include media-max($m) {
        flex-direction: column;
        align-items: flex-start;    
        padding: 20px;
        border: 1px solid #dfdfdf;
        border-radius: 5px;
        margin-top: 20px;
      }
    }
    .profile {
      width: 100px;
      height: 100px;
      border: 1px solid $c-border;
      background: $lightBlue;
      @include flex(all);
      &::before {
        content: '';
        width: 46px;
        height: 46px;
        background: url('../../img/icon_camera.png') no-repeat center;
        background-size: contain;
      }
      @include media-max($m) {
        width: 80px;
        height: 80px;
        &::before {
          width: 36px;
          height: 36px;
        }
      }
      > img {
        object-fit: cover;
      }
    }
    .info_list {
      @include flex(between);
      @include flex-wrap(wrap);
      flex: 1;
      margin-left: 25px;
      @include media-max($m) {
        flex-direction: column;
        width: 100%;
        margin-left: 0px;
        margin-top: 15px;
        padding-top: 10px;
        border-top: 1px solid $c-border;
      }
      .info_item {
        width: calc(100% / 2);
        margin: 6px 0;
        @include flex(s);
        font-size: 14px;
        line-height: 1.43;
        letter-spacing: normal;
        @include media-max($m) {
          width: 100%;
          font-size: 13px;
        }
        .name {
          min-width: 60px;
          font-weight: bold;
          margin-right: 10px;
        }
        .txt {
          width: auto;
          margin-right: 10px;
          @include multi-ellipsis(1);
        }
        .icon {
          display: inline-block;
          height: 22px;
          line-height: 22px;
          padding: 0 5px;
          border: solid 1px #999;
          background-color: #fff;
          font-size: 12px;
          font-weight: 300;
          &.bg_green {
            background-color: #e1f8d9;
          }
          &.line_green {
            border: solid 1px $green;
            color: $green;
          }
        }
      }
    }
    .com_btn_wrap {
      margin-left: auto;
      margin-top: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @include media-max($m) {
        position: absolute;
        left: 115px;
        top: 25px;
      }
      .com_btn {
        min-width: 120px;
        border-radius: 50px;
        flex: auto;
        margin-left: 0;
        & + .com_btn {
          margin-top: 10px;
        }
        @include media-max($xl) {
          min-width: 120px;
        }
        @include media-max($m) {
          min-width: 100px;
        }
      }
    }
  }
}
.p_company_info {
  .btn_resetPw {
    padding: 0 22px;
    font-weight: normal;
  }
  .com_input_upload {
    width: 120px;
    height: 80px;
    @include media-max($m) {
      margin-left: 0px;
      margin-bottom: 8px;
      width: calc((100% / 2) - 5px);
      height: auto;
      align-self: stretch;
    }
  }
}
//!Job
.p_company_job {
  .com_input_sec.type_row .field_box .input {
    &.input_carrer {
      min-width: 50px;
      width: 50px;
    }
  }
  // job
  .com_attach_section {
    @include flex(y);
    @include media-max($m) {
      justify-content: flex-start;
      border: 0;
      padding: 0;
    }
    .img_box {
      margin-bottom: 0;
      margin-right: 20px;
      @include media-max($m) {
        width: auto;
      }
      .img_upload {
        width: 55px;
        height: 45px;
        margin-left: 10px;
      }
    }
  }
  .com_input_upload {
    margin: 0;
    height: 45px;
    border: none;
    @include media-max($m) {
      width: auto;
    }
    .btn_upload {
      padding: 0 22px;
      font-weight: normal;
      font-size: 14px;
      background: none;
      @include media-max($m) {
        width: auto;
      }
    }
  }
  // input
  .input_box {
    .chk_wrap {
      margin-left: 20px;
      label {
        & + input + label {
          margin-left: 20px;
        }
      }
    }
    .input_wrap {
      margin-bottom: 0;
    }
    .txt {
      display: inline-block;
      margin-right: 20px;
      @include media-max($m) {
        margin-right: 10px;
        margin-top: 5px;
      }
    }
  }
  // job detail
  .com_pg_tab {
    &.type_detail {
      margin-top: 40px;
      @include media-max($m) {
        margin-top: 20px;
      }
    }
  }
  .com_tag_list {
    margin-bottom: 20px;
  }
  // contents detail text
  .detail_wrap {
    padding-bottom: 30px;
    @include media-max($m) {
      padding: 20px;
      border-radius: 5px;
      border: solid 1px #dfdfdf;
    }
    .tit {
      margin-top: 30px;
      font-size: 16px;
      font-weight: bold;
      line-height: 1.75;
      @include media-max($m) {
        font-size: 15px;
      }
      & + .txt {
        margin-bottom: 10px;
        margin-top: 2px;
      }
    }
    .txt {
      margin: 20px 0;
      font-size: 16px;
      line-height: 1.75;
      @include media-max($m) {
        font-size: 13px;
      }
    }
  }
  // job list wrap
  .job_wrap {
    padding-top: 27px;
    .list_wrap {
      @include flex(between);
      position: relative;
      .list {
        @include flex(y);
        @include flex-wrap(wrap);
        margin-left: -10px;
        @include media-max($xl) {
          width: calc(100% - 40px);
          margin-left: 0px;
        }
        .com_job_item {
          width: calc((100% / 3) - 10px);
          height: 230px;
          margin-left: 10px;
          @include media-max($xl) {
            width: 100%;
            margin-top: 0;
            display: none;
            margin-left: 0px;
            height: 222px;
            &:first-child {
              display: block;
            }
          }
          @include media-max($m) {
            width: 100%;
          }
        }
      }
      .btn_more_job {
        width: 40px;
        height: 230px;
        margin: 0 0 0 10px;
        padding: 0 10px;
        border-radius: 5px;
        border: solid 1px #ccc;
        background-color: #fff;    
        word-wrap: break-word;
        font-size: 14px;
        line-height: 1.14;
        letter-spacing: normal;
        color: #000;
        &.arrow_right {
          &:after {
            content:'';
            display: inline-block;
            width: 10px;
            height: 10px;
            border: solid $fc-default;
            border-width: 1px 1px 0 0;
            transform: rotate(45deg);
            margin-left: -4px;
            margin-top: 12px;
          }
        }
        @include media-max($xl) {
          height: 222px;
        }
      }
    }
  }
}
.p_company_recruit, .p_company_recruit_status {

  .com_pg_tab {
    width: 100%;
    @include media-max($m) {
      padding: 8px 8px;
      border-radius: 10px;
      height: 60px;
    }
    .tab {
      min-width: calc(100% / 5);
      @include media-max($m) {
        font-size: 12px;
        border-radius: 10px;
        padding-block: 8px;    
        height: 100%;
      }
    }
  }
}
//!Recruit
.p_company_recruit {
  .com_attach_section {
    @include media-max($m) {
      border: 0;
      padding: 0;
    }
    .img_box {
      width: 120px;
      height: 80px;
      border-radius: 5px;
      border: 1px solid #dfdfdf;
      font-size: 14px;
      font-weight: 600;
      line-height: 1.29;
      @include flex(all);
      @include media-max($m) {
        width: calc((100% / 3) - 10px);
      }
      &.active {
        color: $point;
        background: $lightBlue;
        border: 1px solid $point;
      }
    }
  }
  .com_input_upload {
    width: 120px;
    height: 80px;
    @include media-max($m) {
      width: calc((100% / 3) - 10px);
    }
  }
  .pg_tit_wrap {
    @include media-max($m) {
      @include flex(between);
      flex-direction: row;
    }
    .com_pg_tit {
      @include media-max($m) {
        font-size: 18px;
      }
    }
  }
  .graph {
    margin-right: 20px;
    @include media-max($m) {
      position: absolute;
      right: 0;
      top: 50px;
    }
    .percent {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.43;
      letter-spacing: normal;
      color: #010101;
    }
    .icon_graph {
      display: block;
      width: 25px;
      height: 21px;
    }
  }
  .btn_like {
    &::before {
      content: '';
      display: inline-block;
      margin-right: 4px;
      width: 14px;
      height: 12px;
      background: url('../../img/icon_like_on.png') no-repeat 0 0;
      background-size: contain;
    }
  }
  .notice_txt {
    position: relative;
    margin-bottom: 16px;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.43;
    .btn_del {
      min-width: 60px;
      height: 30px;
      position: absolute;
      right: 0;
      top: -7px;
    }
    &.type_suggestion {
      @include media-max($m) {
        display: none;
      }
    }
  }
  .btn_refresh {
    min-width: 120px;
    &::before {
      content: '';
      display: inline-block;
      margin-right: 4px;
      width: 20px;
      height: 20px;
      background: url('../../img/icon_refresh.png') no-repeat 0 0;
      background-size: contain;
    }
  }
  // board list 
  .com_board_list {
    @include media-max($m) {
      border: none;
    }
    .com_btn {
      padding: 0 10px;
    }
    .com_chk {
      & + label {
        &::before {
          margin-top: -14px;
        }
      }
    }
    tr {
      @include media-max($m) {
        position: relative;
        border: 1px solid #ccc;
        border-radius: 10px;
        & + tr {
          margin-top: 20px;
        }
        .td_m {
          display: none;
        }
        .tit_field {
          &.first {
            order: 0;
          }
          &.com_point_txt {
            color: $point;
          }
        }
        .info_field {
          &::after {
            display: none;
          }
          &.info_date {
            position: absolute;
            right: 20px;
            top: 20px;
            font-size: 12px;
            line-height: 1.2;
            letter-spacing: normal;
            color: #999;
          }
          &.info_status {
            position: absolute;
            right: 20px;
            bottom: 54px;
            font-size: 13px;
            line-height: 1.2;
          }
          &.info_btn {
            order: 4;
            width: 40%;
            margin-right: 10px;
            margin-top: 6px;
            .com_btn {
              width: 100%;
            }
          }
          &.info_apply_date {
            width: calc(100% - 120px);
            @include divider(2px,2px,5px);
            &::before{
              content: '';
              display: inline-block;
              width: 2px;
              height: 2px;
              margin: 0 5px;
              vertical-align: middle;
              background-color: #333;
              border-radius: 5px;
            }
            &::after{
              display: none;
            }
          }
          &.info_due_date {
            width: 100%;
            margin-bottom: 6px;
          }
        }
      }
    }
  }
  // input
  .com_input_sec {
    .textarea {
      min-height: 400px;
    }
    .field_box {
      .tit {
        &.m_hide {
          @include media-max($m) {
            display: none;
          }
        }
      }
    }
    .btn_wrap {
      padding: 10px 0;
      @include media-max($m) {
        padding: 0;
      }
      @include flex(between);
      .com_btn {
        min-width: 100px;
      }
    }
  }
  .recruit_status {
    .input_wrap {
      @include flex(y);
      justify-content: flex-end;
      margin-bottom: 30px;
      @include media-max($m) {
        margin-bottom: 20px;
      }
      .input {
        width: 200px;
        @include media-max($xl) {
          width: 100%;
        }
      }
      .btn_search {
        min-width: 50px;
        height: 45px;
      }
    }
    .status_list {
      .item {
        border-radius: 10px;
        border: solid 1px #ccc;
        & + .item {
          margin-top: 20px;
        }
        .tit_box {
          padding: 16px 20px;
          border-bottom: 1px solid #dfdfdf;
          @include media-max($xl) {
            border-bottom: 0;
            padding-bottom: 0;
          }
          .status {
            display: inline-block;
            color: $point;
            font-size: 14px;
            font-weight: bold;
            line-height: 1.43;
            letter-spacing: normal;
            margin-right: 10px;
            @include media-max($xl) {
              display: block;
              font-size: 13px;
            }
          }
          .tit {
            font-size: 18px;
            font-weight: 600;
            line-height: 1.44;
            letter-spacing: normal;
            color: #2c2c2c;
            @include media-max($xl) {
              font-size: 14px;
            }
          }
        }
        .list_box {
          @include flex(between);
          padding: 20px 20px;
          @include media-max($xl) {
            flex-direction: column;
            align-items: flex-start;
            padding: 8px 20px 20px;
          }
          .info_date {
            margin-left: auto;
            color: #999;
            font-size: 14px;  
            line-height: 2;
            @include media-max($xl) {
              margin-bottom: 8px;
              font-size: 12px;
              order: 1;
            }
            @include media-max($xl) {
              margin-bottom: 0;
            }
            .date {
              color: $fc-default;
            }
            .info_txt {
              @include flex(s);
              & + .info_txt {
                @include media-max($xl) {
                  margin-left: 10px;
                }
              }
            }
            @include media-max($xl) {
              margin-left: 0;
              @include flex(s);
            }
          }
          .status_wrap {
            @include flex(s);
            justify-content: flex-start;
            @include flex-wrap(wrap);
            border: none;
            padding-left: 0;
            margin-left: -20px;
            @include media-max($xl) {
              order: 2;
              width: 100%;
              justify-content: space-between;
              margin-left: -10px;
            }
            .status_link {
              @include flex(all);
              width: 110px;
              height: 80px;
              background: $lightBlue;
              border: 1px solid $point;
              border-radius: 5px;
              margin-left: 20px;
              font-size: 14px;
              font-weight: 600;
              line-height: 1.29;
              color: $point;
              @include media-max($xl) {
                margin-bottom: 0;
                width: calc((100% / 2) - 10px);
                margin-left: 10px;
                margin-top: 10px;
              }
            }
          }
        }
      }
    }
  }
}
//채용상태
.p_company_recruit_status {
  .com_board_list {
    @include media-max($m) {
      border: none;
    }
    .com_btn {
      padding: 0 10px;
    }
    tr {
      @include media-max($m) {
        position: relative;
        border: 1px solid #ccc;
        border-radius: 10px;
        & + tr {
          margin-top: 20px;
        }
        .info_field {
          &::after {
            display: none;
          }
          &.info_btn {
            order: 4;
            width: 40%;
            margin-right: 10px;
            margin-top: 6px;
            .com_btn {
              width: 100%;
            }
          }
          &.info_apply_date {
            width: calc(100% - 120px);
            @include divider(2px,2px,5px);
            &::before{
              content: '';
              display: inline-block;
              width: 2px;
              height: 2px;
              margin: 0 5px;
              vertical-align: middle;
              background-color: #333;
              border-radius: 5px;
            }
            &::after{
              display: none;
            }
          }
        }
      }
      .select {
        width: 100px;
        height: 30px;
        line-height: 30px;
        border-radius: 30px;
      }
    }
  }
}
//채용관리 상세
.p_company_recruit_detail {
  .detail_wrap {
    @include media-max($m) {
      padding: 20px 0;
    }
  }
  .pg_tit_wrap {
    @include media-max($m) {
      @include flex(between);
      flex-direction: row;
    }
    .com_pg_tit {
      @include media-max($m) {
        font-size: 18px;
      }
    }
  }
  .btn_refresh {
    min-width: 120px;
    margin-right: 30px;
    &::before {
      content: '';
      display: inline-block;
      margin-right: 4px;
      width: 20px;
      height: 20px;
      background: url('../../img/icon_refresh.png') no-repeat 0 0;
      background-size: contain;
    }
  }
  .graph {
    margin-right: 20px;
    @include media-max($m) {
      position: absolute;
      right: 0;
      top: 50px;
    }
    .percent {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.43;
      letter-spacing: normal;
      color: #010101;
    }
    .icon_graph {
      display: block;
      width: 25px;
      height: 21px;
    }
  }
  .btn_like {
    &::before {
      content: '';
      display: inline-block;
      margin-right: 4px;
      width: 20px;
      height: 20px;
      background: url('../../img/icon_like_blue.png') no-repeat 0 0;
      background-size: contain;
    }
  }
}
//!Resume
.p_company_resume {
  .profile_list {
    border: none;
    .item {
      border-bottom: none;
      @include media-max($m) {
        border-bottom: 1px solid #dfdfdf;
        margin-top: 0;
        flex-direction: row;
      }
      .info_list {
        @include media-max($m) {
          margin-top: 0;
          padding-top: 0;
          margin-left: 15px;
          border-top: 0;
        }
      }
      .com_btn_wrap {
        margin-top: auto;
        margin-bottom: 10px;
        @include media-max($m) {
          left: 20px;
          top: 110px;
          .com_btn {
            min-width: 80px;
          }
        }
      }
    }
  }
  .profile_info {
    @include media-max($m) {
      border: 1px solid #dfdfdf;
      padding: 20px 20px;
      border-radius: 5px;
      margin-top: 16px;
    }
    .graph_wrap {
      position: relative;
      padding: 30px 0;
      @include media-max($m) {
        width: 100%;
        padding: 20px 0 10px;
      }
      .graph_list {
        min-width: 400px;
        height: 60px;
        padding: 0 150px;
        border-bottom: 1px solid $fc-default;
        border-top: 1px solid #f4f6f8;
        @include flex(all);
        @include media-max($xl) {
          min-width: 280px;
          height: 50px;
          padding: 0 100px;
        }
        @include media-max($m) {
          min-width: auto;
          padding: 0 20px;
        }
        .graph_item {
          position: relative;
          width: calc((100% / 3) - 120px);
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          font-size: 14px;
          line-height: 1.43;
          letter-spacing: normal;
          color: #666;
          @include media-max($xl) {
            width: calc((100% / 3) - 100px);
            font-size: 12px;
          }
          @include media-max($m) {
            width: calc((100% / 3) - 30px);
            font-size: 12px;
          }
          & + .graph_item {
            margin-left: 120px;
            @include media-max($xl) {
              margin-left: 100px;
            }
            @include media-max($m) {
              margin-left: 30px;
            }
          }
          .bg {
            width: 100%;
            .bg_txt {
              position: relative;
              display: inline-block;
              width: 100%;
              top: -20px;
              text-align: center;
            }
            &.bg_blue {
              background: $point;
            }
            &.bg_emerald {
              background: #01be9d;
            }
            &.bg_green {
              background: $green;
            }
          }
          .graph_txt {
            position: absolute;
            bottom: -25px;
            text-align: center;
            width: 100%;
          }
        }
      }
    }
    .com_info_sec {
      margin-top: 30px;
      padding-top: 20px;
      border-bottom: 0;
      @include media-max($m) {
        padding: 0;
        border: 0;
        border-top: 1px solid #dfdfdf;
        border-radius: 0;
      }
      .field_box {
        border-bottom: 0;
        align-items: flex-start;
        @include media-max($m) {
          flex-direction: column;
          border-bottom: 1px solid #dfdfdf;
        }
      }
      .info_box {
        font-size: 14px;
        @include media-max($m) {
          margin-top: 8px;
          font-size: 13px;
        }
        .txt {
          & + .txt {
            margin-top: 4px;
          }
          &.download {
            @include flex(y);
          }
        }
        .icon_certify {
          display: inline-block;
          width: 50px;
          margin-left: 10px;
          > img {
            margin-top: -5px;
          }
        }
      }
    }
  }
  .btn_print {
    &::before {
      content: '';
      display: inline-block;
      margin-right: 4px;
      width: 20px;
      height: 20px;
      background: url('../../img/icon_print.png') no-repeat 0 0;
      background-size: contain;
      @include media-max($m) {
        display: none;
      }
    }
  }
  .btn_download {
    margin-left: 5px;
    &::before {
      content: '';
      display: inline-block;
      margin-right: 4px;
      width: 27px;
      height: 27px;
      background: url('../../img/icon_download.png') no-repeat 0 0;
      background-size: contain;
      @include media-max($m) {
        display: none;
      }
    }
  }
}
//!Vision
.p_vision {
  .tit_wrap {
    padding: 20px 0 15px;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
    border-bottom: 1px solid $c-border;
  }
  .work_list {
    @include flex(between);
    @include flex-wrap(wrap);
    .work_item {
      width: calc((100% / 4) - 8px);
      padding: 17px 15px;
      background: #f5f5f9;
      border: 1px solid #dfdfdf;
      border-radius: 10px;
      @include media-max($m) {
        width: calc((100% / 2) - 8px);
        margin-bottom: 16px;
      }
      &.full {
        width: 100%;
        margin-top: 20px;
        @include media-max($m) {
          margin-top: 0px;
        }
        .check_list {
          @include flex(between);
          @include flex-wrap(wrap);
        }
        .check_item {
          width: calc((100% / 2) - 3px);
          margin-bottom: 5px;
          @include media-max($m){
            width:100%;
          }
          & + .check_item {
            margin-top: 0;
          }
          .item_txt {
            @include multi-ellipsis(1);
          }
        }
      }
      .tit {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 10px;
      }
      .check_item {
        @include flex(y);
        padding: 0 10px;
        height: 50px;
        line-height: 1.2;
        background: #fff;
        border-radius: 5px;
        font-size: 14px;
        letter-spacing: -0.38px;
        @include media-max($m) {
          font-size: 13px;
        }
        & + .check_item {
          margin-top: 5px;
        }
        .com_chk {
          & + label {
            text-align: left;
            padding-left: 30px;
            font-size: 14px;
            line-height: 1.43;
            letter-spacing: normal;
            &::before {
              left: 25px;
            }
          }
        }
        .select_box {
          @include flex(y);
          margin-left: auto;
          .input_rdo {
            display: none;
            & + label {
              display: inline-block;
              width: 28px;
              height: 28px;
              &::before {
                content: '';
                display: inline-block;
                width: 28px;
                height: 28px;
                border-radius: 100%;
                background: url('../../img/icon_no_off.png') no-repeat center;
                background-size: contain;
              }
            }
            &:checked+label:before {
              background: url('../../img/icon_no_on.png') no-repeat center;
              background-size: contain;
            }
            &.type_yes {
              & + label:before {
                background: url('../../img/icon_yes_off.png') no-repeat center;
                background-size: contain;
              }
              &:checked+label:before {
                background: url('../../img/icon_yes_on.png') no-repeat center;
                background-size: contain;
              }
            }
            & + .input_rdo {
              margin-left: 15px;
            }
          }
        }
      }
    }
  }
  .com_tag_list {
    padding-bottom: 20px;
    border-bottom: 1px solid #e4e5ed;
    @include media-max($m) {
      margin-top: -10px;
    }
    .com_btn {
      height: 40px;
    }
  }
  .com_btn_wrap {
    .com_btn {
      flex: initial;
    }
  }
}
//!Notice 
.p_notice, .p_cs {
  .com_board_list {
    tr {
      &.isRead {
        td {
          color: #cfcfcf;
        }
      }
    }
    td {
      &.left {
        text-align: left;
        padding: 6px 0 6px 30px;
        @include media-max($m) {
          padding: 0;
        }
      }
      &.info_field {
        &:after {
          display: none;
        }
      }
    }
  }
}
.p_cs {
  .top_wrap {
    @include flex(between);
    align-items: flex-end;
    padding: 0 0 20px 0;
    margin-top: -25px;
    @include media-max($m) {
      flex-direction: column;
      align-items: center;
      margin-top: 0;
    }
    .cs_tab {
      @include flex(s);
      @include media-max($m) {
        margin-bottom: 20px;
      }
      .cs_tab_item {
        @include divider(1px,12px,10px);
        font-size: 14px;
        font-weight: bold;
        line-height: 1.14;
        &.active {
          color: $point;
        }
        &:last-child {
          &::after {
            display: none;
          }
        }
      }
    }
    .input_wrap {
      @include flex(y);
      justify-content: flex-end;
      @include media-max($m) {
        width: 100%;
      }
      .input {
        width: 200px;
        @include media-max($xl) {
          width: 100%;
        }
      }
      .btn_search {
        min-width: 50px;
        height: 45px;
      }
    }
  }
  .faq_title {
    position: relative;
    &.active {
      & + .answer {
        display: table-column;
        max-height: 0;
        transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
      }
    }
    &::after {
      content: "";
      position: absolute;
      top: 16px; 
      right: 0;
      display: block;
      width: 44px; height: 44px;
      background:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 36.77 36.77'%3E%3Cpath fill='%237e7e7e' d='M15.628 34.012L0 18.385l2.757-2.758 15.627 15.627 15.628-15.627 2.757 2.758L18.385 36.77z'/%3E%3C/svg%3E") no-repeat center;
      background-size: 12px auto;
    }
    &.active::after {
      transform: rotate(180deg);
    }
    & + .answer {
      overflow: hidden;
      transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
      height: auto;
      max-height: 9999px;
    }
  }
  .com_board_list {
    tr {
      &.answer {
        @include media-max($m) {
          padding: 0;
        }
        td {
          text-align: left;
          background: #f6f6f6;
          padding: 17px 20px;
        }
      }
      .info_field {
        @include media-max($m) {
          order: 1;
          color: $point;
          font-weight: bold;
          margin-bottom: 4px;
        }
      }
      .tit_field {
        @include media-max($m) {
          order: 2;
          margin-bottom: 0;
        }
      }
    }

  }
  .agree_wrap {
    margin-top: 30px;
    padding: 24px 25px;
    background: #f5f5f9;
    border: 1px solid #dfdfdf;
    border-radius: 10px;
    font-size: 14px;
    line-height: 1.5;
    .tit {
      margin-bottom: 10px;
      font-weight: bold;
    }
    .desc {
      margin-top: 10px;
    }
  }
  .agree_chk_wrap {
    margin-top: 25px;
    text-align: center;
  }
  .com_input_upload {
    margin: 0;
    margin-left: 10px;
    height: 45px;
    border: none;
    @include media-max($m) {
      width: auto;
    }
    .btn_upload {
      padding: 0 22px;
      font-weight: normal;
      font-size: 14px;
      background: none;
      @include media-max($m) {
        width: auto;
        padding: 0 10px;
      }
    }
  }
}
@import "../common/_variables.scss";

.p_seminar {
  .sec_seminar {
    padding: 30px 0 130px;
    @include media-max($m) {
      padding: 30px 0 50px;
    }
    .com_center_wrap {
      @include media-max($xl) {
        padding-inline: $pd-inline;
      }
      .input_box {
        @include flex(y);
        max-width: 240px;
        margin-left: auto;
        @include media-max($m) {
          max-width: 100%;
          padding-bottom: 0;
        }
        &.icon_search {
          &::after {
            content: "";
            display: inline-block;
            width: 20px;
            height: 20px;
            background: url("../../img/icon_search.png") no-repeat 0 0;
            background-size: contain;
            margin-left: -32px;
          }
        }
        .input {
          border-radius: 5px;
          padding: 0 40px 0 15px;
        }
      }
      .com_card_wrap {
        margin-left: -40px;
        @include media-max($xl) {
          margin-top: 26px;
          margin-left: -20px;
        }
        .com_card_item {
          display: flex;
          flex-direction: column;
          align-self: stretch;
          min-width: calc((100% / 4) - 40px);
          background-color: $white;
          padding-bottom: 28px;
          min-height: 410px;
          border-radius: 20px;
          margin-left: 40px;
          margin-bottom: 40px;
          border: solid 1px #ccc;
          overflow: hidden;
          @include media-max($xl) {
            min-width: calc((100% / 2) - 20px);
            margin-left: 20px;
            margin-bottom: 20px;
            min-height: auto;
          }
          @include media-max($m) {
            min-width: calc(100% - 20px);
            border-radius: 5px;
            padding-bottom: 23px;
          }
          .thumb_img {
            // height: 165px;
            aspect-ratio: 725/611;
            width: 100%;
            object-fit: cover;
            // @include media-max($m) {
            //   height: 199px;
            // }
          }
          .info {
            margin: 0;
            padding-inline: 22px;
            margin-bottom: 15px;
            .title {
              padding: 25px 0 12px;
              font-size: 20px;
              font-weight: 600;
              line-height: 1.2;
              @include media-max($m) {
                font-size: 16px;
              }
            }
            .detail {
              @include flex(_);
              gap: 5px;
              font-size: 14px;
              @include media-max($m) {
                font-size: 13px;
              }
              .text_grey {
                color: #999;
              }
            }
          }
          .com_btn {
            display: block;
            margin: 0 auto;
            padding: 0;
            width: 210px;
            height: 40px;
            border-radius: 20px;
            font-size: 18px;
            font-weight: 600;
            margin-top: auto;
          }
          .disabled {
            color: #999;
            border: none;
            background-color: $bg;
          }
        }
      }
      .list_wrap {
        padding-bottom: 40px;
      }
    }
  }
}

.com_detail {
  .com_center_wrap {
    padding-inline: 0;
    @include media-max($xl) {
      padding-inline: 20px;
      padding-top: 20px;
    }
    .sec_detail {
      max-width: 1200px;
      width: 100%;
      margin-inline: 0;
      .content_wrap {
        @include flex(x);
        gap: 30px;
        @include media-max($xl) {
          @include flex(column);
          gap: 20px;
        }
        .image_wrap,
        .title_wrap {
          background-color: $white;
          border: 1px solid #ccc;
          border-radius: 5px;
          padding: 30px;
          width: 100%;
          flex: 1;
          @include media-max($xl) {
            padding: 20px;
          }
        }
        .image_wrap {
          @include flex(y);
          @include media-max($m) {
            @include flex(column);
          }
          gap: 20px;
          .img {
            @include thumb(auto,190px);
            aspect-ratio: 725/611;
            //height: 190px;
            //object-fit: cover;
            @include media-max($m) {
              width: 100%;
              height: auto;              
            }
          }
          .address_wrap {
            @include flex(column);
            gap: 16px;
            @include media-max($m) {
              width: 100%;
            }
            .detail_wrap {
              padding-left: 30px;
              position: relative;
              @include flex(column);
              gap: 6px;
              @include media-max($m) {
                flex-direction: row;
              }
              &::before {
                content: "";
                position: absolute;
                left: 0;
                height: 20px;
                width: 20px;
              }
              &.icon_calender::before {
                background: url("../../img/icon_calendar.svg") no-repeat 0 0;
                background-size: contain;
              }
              &.icon_location::before {
                background: url("../../img/icon_location.png") no-repeat 0 0;
                background-size: contain;
              }
              &.icon_multi_user::before {
                background: url("../../img/icon_multi_user.png") no-repeat 0 0;
                background-size: contain;
              }
              .title,
              .detail {
                font-size: 14px;
                @include media-max($xl) {
                  font-size: 13px;
                }
              }
              .title {
                color: #999;
              }
            }
          }
        }
        .title_wrap {
          padding: 26px 30px 20px;
          @include media-max($xl) {
            padding: 20px;
          }
          .title {
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 10px;
            @include media-max($xl) {
              font-size: 18px;
            }
          }
          .description {
            font-size: 14px;
            color: #666;
            margin-bottom: 18px;
            @include media-max($xl) {
              font-size: 13px;
              margin-bottom: 10px;
            }
          }
          .com_tag_list {
            padding-bottom: 25px;
            margin-bottom: 20px;
            border-bottom: 1px solid $c-border;
            @include media-max($xl) {
              padding-bottom: 20px;
              .com_btn {
                font-size: 12px;
              }
            }
          }
          .wrapper {
            @include flex(between);
            @include media-max($m) {
              @include flex(column);
              align-items: flex-start;
              gap: 15px;
            }
            .other_detail {
              .desc {
                display: flex;
                margin-bottom: 5px;
                font-size: 14px;
                .divider {
                  @include divider(1px, 10px, 10px);
                }
                .bold {
                  font-weight: 800;
                }
                .counter {
                  margin: 0 8px;
                  height: 20px;
                  width: 20px;
                  &.minus {
                    background: url("../../img/icon_minus.png") no-repeat 0 0;
                    background-size: contain;
                  }
                  &.plus {
                    background: url("../../img/icon_plus.png") no-repeat 0 0;
                    background-size: contain;
                  }
                }
              }
            }
            .com_btn {
              font-size: 20px;
              font-weight: bold;
              border-radius: 25px;
              min-width: 130px;
              @include media-max($m) {
                border-radius: 5px;
                width: 100%;
                height: 50px;
              }
            }
          }
        }
      }
      .seminar_description {
        .btn_wrapper {
          @include flex(x);
          .com_btn {
            font-size: 18px;
            font-weight: 500;
            border-radius: 5px 5px 0 0;
            padding: 16px 20px;
            background-color: $white;
            border: 2px solid $c-border;
            border-bottom: 2px solid $fc-default;
            &.active {
              border: 2px solid $fc-default;
              border-bottom: none;
              z-index: 1;
            }
            & + .com_btn {
              margin-left: -2px;
            }
            @include media-max($m) {
              width: 100%;
              height: 45px;
              font-size: 14px;
              min-width: auto;
            }
          }
          .space {
            display: inline-block;
            height: auto;
            width: 100%;
            border-bottom: 2px solid $fc-default;
            @include media-max($m) {
              width: 0;
            }
          }
        }
        .description_wrap {
          border-top: none;
          border-radius: 0 0 5px 5px;
          @include media-max($m) {
            padding: 20px;
          }
          .desc {
            margin-left: 7px;
            &::before {
              content: "";
              display: inline-block;
              height: 4px;
              width: 4px;
              margin: 0 7px 4px -10px;
              background-color: $point;
              border-radius: 50%;
            }
          }
          .map {
            height: 352px;
            background-color: $bg;
          }
        }
      }
    }
  }
}

.p_seminar_pay {
  .com_info_sec {
    border-top: 0;
  }
}

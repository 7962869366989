//!^text style

$err: #ff0000;
$black: #000000;

.com_error_text {
  color: $err;
}

.com_point_txt {
  color: $point;
}
// !page title
.com_pg_tit {
  font-size: 30px;
  font-weight: 800;
  line-height: 1.17;
  letter-spacing: normal;
  color: $black;
  @include media-max($m) {
    font-size: 20px;
  }
}

@import "../common/variables";

.p_magazine,
.p_recommend {
  .sec_magazine,
  .sec_recommend {
    background-color: $bg;
    padding-top: 30px;
    .com_center_wrap {
      @include media-max($xl) {
        padding-inline: $pd-inline;
      }
      .com_tab_wrap {
        .content_tab {
          @include flex(y);
          gap: 5px;
          gap: 15px;
          width: 100%;
          & > .tab {
            min-width: 50px;
            @include media-max($xl) {
              display: none;
            }
          }
          .swiper_wrap {
            @include flex(y);
            gap: 5px;
            position: relative;
            &.category {
              width: 100%;
              height: 38px;
              display: none;
              @include media-max($xl) {
                display: block;
              }
              .swiper {
                width: calc(100% - 100px);
                text-align: center;
              }
              .swiper-slide {
                min-width: 60px;
                width: auto !important;
                padding: 0;
              }
              .swiper-button-prev {
                left: 0;
              }
              .swiper-button-next {
                right: 0;
              }
              .swiper-button-prev,
              .swiper-button-next {
                min-width: 38px;
                height: 38px;
                border-radius: 100%;
                background: $white;
                color: $point;
                box-shadow: 0 0 20px 0 rgba(0, 53, 255, 0.3);
                &::after {
                  font-size: 14px;
                  font-weight: bold;
                }
                &.swiper-button-disabled {
                  color: #999;
                  opacity: 1;
                  box-shadow: 0 0 20px 0 rgba(102, 102, 102, 0.2);
                }
              }
            }
          }
        }
      }
      .list_wrap {
        & + .list_wrap {
          margin-top: 80px;
          @include media-max($m) {
            margin-top: 38px;
          }
        }
        .tit {
          @include flex(y);
          gap: 20px;
          font-size: clamp(15px, 3vw, 24px);
          font-weight: 600;
          @include media-max($m) {
            @include flex(between);
          }
          .more {
            font-size: 14px;
            padding: 8px 20px;
            border-radius: 50px;
            border: 1px solid $black;
            background: $white;
            @include media-max($m) {
              padding: 1px 15px;
            }
          }
        }
        .com_card_wrap {
          @include media-max($xl) {
            margin-left: -20px;
            margin-top: 20px;
          }
          @include media-max($m) {
            margin-left: 0;
            margin-top: 15px;
          }
          .com_card_item {
            background-color: $white;
            padding: 37px 32px;
            border-radius: 20px;
            align-self: stretch;
            margin-bottom: 32px;
            @include media-max($xl) {
              min-width: calc((100% / 2) - 20px);
              margin-left: 20px;
              margin-bottom: 20px;
            }
            @include media-max($m) {
              min-width: 100%;
              margin-left: 0px;
              margin-bottom: 20px;
              padding: 20px;
              align-self: auto;
            }
            .thumb {
              @include thumb(315px,130px);
              //max-height: 130px;
              border-radius: 0px;
              @include media-max($xl) {
                @include thumb(100%,auto);
                aspect-ratio: 315/130;
              }
            }
            .info {
              @include media-max($m) {
                margin-top: 15px;
              }
              .icon_wrap {
                @include flex(y);
                justify-content: left;
                flex-wrap: wrap;
                gap: 5px;
                @include media-max($m) {
                  margin-bottom: 10px;
                }
                .icon_line_wrap {
                  font-size: 16px;
                }
              }
              .title,
              .description {
                margin-top: 20px;
                @include media-max($m) {
                  margin-top: 10px;
                }
              }
              .title {
                font-size: 20px;
                font-weight: 600;
                @include media-max($m) {
                  font-size: 14px;
                }
              }
              .description {
                font-size: 16px;
                padding: 20px 20px;
              }
              .info_s {
                @include multi-ellipsis(2);
                line-height: 1.5;
              }
            }
          }
        }
      }
      .com_paging {
        padding: 30px 0 110px;
      }
    }
  }
}

.p_recommend .sec_recommend {
  .title_wrap {
    @include flex(between);
    gap: 20px;
    @include media-max($m) {
      @include flex(column);
    }
    .title {
      font-size: 24px;
      font-weight: bold;
    }
  }
  .btn_all_list {
    margin-left: auto;
    font-size: 15px;
    min-width: 150px;
    @include media-max($m) {
      width: 100%;
      margin-top: 0px;
    }
  }
  .select_wrap {
    text-align: right;
  }
  .dropdown {
    width: 115px;
    margin-left: auto;
    margin-top: 40px;
    padding-inline: 20px;
    height: 40px;
    border-radius: 50px;
    line-height: 40px;
    @include media-max($m) {
      flex: 1;
      padding: 0 20px;
      margin-top: 10px;
    }
  }
  .com_card_wrap {
    margin-top: 23px;
  }
}

// Detail component
.p_magazine_detail.com_detail {
  .com_center_wrap.flex {
    align-items: flex-start;
    padding-inline: 0;
    @include media-max($m) {
      padding-top: 20px;
    }
    .sec_detail {
      @include media-max($xl) {
        padding-inline: 20px;
      }
      .title_wrap {
        @include flex(column);
        gap: 15px;
        padding: 20px 20px;
        .tit {
          font-size: 24px;
          font-weight: bold;
          @include media-max($xl) {
            font-size: 18px;
          }
        }
        .time {
          font-size: 12px;
          font-weight: 600;
          color: #999;
        }
      }
    }
    .sec_other {
      @include media-max($xl) {
        padding-inline: 20px;
      }
      .magazine_list {
        .content_wrap {
          @include flex(column);
          padding-inline: 12px;
          .img {
            @include thumb(100%,auto);
            aspect-ratio: 1093/450;
            object-fit: cover;
            // @include media-max($xl) {
            //   @include thumb(100%,300px);
            // }
            // @include media-max($m) {
            //   @include thumb(100%,200px);
            // }
            // @include media-max($s) {
            //   @include thumb(100%,115px);
            // }
          }
          .description {
            @include media-max($m) {
              width: 100%;
            }
          }
        }
      }
    }
  }
  .sec_detail {
    .description_wrap {
      @include media-max($xl) {
        padding: 20px 20px;
      }
    }
  }
}

// !popup 공통
.com_popup {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  z-index: 100;
  // display: none;
  display: flex;
  justify-content: center;
  align-items: center;
  .bg_close {
    //bg클릭시 팝업이 닫히게 하기위함
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.7);
    @include media-max($m) {
      // display: none;
    }
  }
  .pop_container {
    position: relative;
    background-color: #fff;
    margin-inline: auto;
    z-index: 101;
    overflow: hidden;
    border-radius: 5px;
    @include media-min($m) {
      //이상
      width: 420px;
      max-width: 80%;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);
    }
    @include media-max($m) {
      width: 80vw;
    }
    &.l {
      min-width: 1000px;
      @include media-max($xl) {
        min-width: auto;
        width: 100%;
      }
    }
    &.s {
      @include media-min($m) {
        width: 320px;
      }
    }
  }
  .content_container {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(80vh - 105px);
    padding: 30px 25px;
    scrollbar-face-color: #999;
    /*스크롤*/
    scrollbar-highlight-color: #999;
    /*하이라이트*/
    scrollbar-3dlight-color: #999;
    /*왼쪽외곽음영*/
    scrollbar-darkshadow-color: #999;
    /*오른쪽외곽음영*/
    scrollbar-shadow-color: #999;
    /*오른쪽 안쪽음영*/
    scrollbar-arrow-color: #999;
    /*화살*/
    scrollbar-track-color: #999;
    /*스크롤영역*/
    overflow: auto;
    font-size: 14px;
    line-height: 1.47;
    letter-spacing: -0.38px;
    text-align: left;
    @include media-max($m) {
      padding-inline: $pd-inline !important;
    }
    &::-webkit-scrollbar {
      opacity: 0;
      width: 10px;
      height: 10px;
    }

    /*스크롤바*/
    &::-webkit-scrollbar-button {
      width: 0;
      height: 0;
    }

    /*스크롤바 버튼(아래 위 화살표)*/
    &::-webkit-scrollbar-thumb {
      background-color: #999;
      background-clip: padding-box;
      //border: 4px solid transparent;
      //border-radius: 10px;
    }

    /*스크롤바 핸들*/
    &::-webkit-scrollbar-track {
      background-color: #f8f8f8;
    }

    /*스크롤바 진행줄*/
    &::-webkit-scrollbar-track-piece {
      background-color: #f8f8f8;
    }

    /*진행줄에서 핸들로 덮이지 않은 영역 */
    &::-webkit-scrollbar-corner {
      background: #f8f8f8;
    }

    @include media-max($m) {
      max-height: calc(100vh - 180px);
      padding: 20px 20px 30px;
    }
    .com_table {
      @include media-max($m) {
        margin: 0;
        width: 100%;
      }
    }
    .btn_container {
      padding: 0 0 30px;
    }
    > .com_input_sec {
      margin-block: -20px -14.5px;
    }
  }
  .btn_container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 25px 30px;
    @include media-max($m) {
      padding: 0 $pd-inline 20px;
    }
    .com_btn {
      min-width: 100px;
      flex: 1;
      & + .com_btn {
        margin-left: 10px;
      }
      @include media-max($m) {
        width: 100%;
      }
    }
  }
  .pop_tit {
    padding: 16px 29px 15px;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    color: #333;
    text-align: left;
    border-bottom: 1px solid $c-border;
    @include media-max($m) {
      font-size: 18px;
      padding: 14px $pd-inline 10px;
    }
    &.type_center {
      padding: 30px 30px 10px;
      border-bottom: 0;
      text-align: center;
      @include media-max($m) {
        font-size: 15px;
      }
      & + .content_container {
        padding: 20px 25px 30px;
      }
    }
  }
  .btn_close {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 20px;
    height: 20px;
    background-image: url(../../img/icon_close.png);
    background-size: 20px;
    background-repeat: no-repeat;
  }
  .notice {
    padding-bottom: 10px;
    margin-top: -15px;
    text-align: center;
  }
  &.type_alert {
    .pop_container {
      max-width: 320px;
    }
    .content_container {
      padding: 40px 30px 30px 30px;
      text-align: center;
      > .com_input_sec {
        margin-block: 0;
      }
      strong {
        display: inline-block;
        margin-bottom: 4px;
        &.pop_sub_tit {
          font-size: 18px;
          margin-bottom: 16px;
        }
      }
      .point_txt {
        color: $point;
      }
    }
  }
}

//^팝업별 스타일 each popup style

//!회원가입>사업자등록번호>등록확인
.pop_company_chk {
  text-align: center;
  .tit {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.56;
    text-align: center;
    margin-bottom: 20px;
    @include media-max($m) {
      font-size: 14px;
      font-weight: 600;
    }
  }
  .detail {
    font-size: 15px;
    line-height: 1.47;
    margin-top: 15px;
    text-align: center;
    @include media-max($m) {
      font-size: 13px;
    }
  }
  .id_sec {
    .item {
      background-color: $bg;
      border-radius: 5px;
      padding: 16px;
      font-size: 15px;
      color: $point;
      text-align: center;
      font-weight: bold;
      margin-top: 10px;
      @include media-max($m) {
        font-size: 14px;
      }
    }
  }
}
// 공고 지원하기
.pop_apply {
  .content_container {
    padding: 0;
  }
  .list {
    margin-bottom: 20px;
    .item {
      height: 50px;
      line-height: 50px;
      background: #f5f7ff;
      border-radius: 5px;
      text-align: center;
      font-size: 15px;
      font-weight: bold;
      letter-spacing: -0.38px;
      color: #222;
      & + .item {
        margin-top: 10px;
      }
    }
  }
  .info_bg {
    background: #f8f8f8;
    .info_wrap {
      @include flex(y);
      margin-top: 0;
      border-bottom: 1px solid #000;
      padding: 26px 25px;
    }
  }
  .img_logo {
    width: 100px;
    padding: 30px 5px;
    border: 1px solid $c-border;
    background: #fff;
    margin-right: 15px;
  }
  .info_wrap {
    padding: 26px 25px 10px;
    @include media-max($m) {
      padding: 26px 0 10px;
    }
    .box {
      padding-bottom: 30px;
      margin-bottom: 0;
      // border-bottom: 1px solid $c-border;
    }
    .input_tit {
      margin-top: 25px;
      margin-bottom: 15px;
      font-size: 18px;
      font-weight: bold;
      line-height: 1.56;
      letter-spacing: normal;
      color: $point;
      &.first {
        margin-top: 0;
      }
      @include media-max($m) {
        font-size: 15px;
        margin-top: 20px;
        margin-bottom: 6px;
      }
    }
    .list {
      .item {
        text-align: left;
        padding: 0 15px 0 20px;
        .sel_tit {
          flex: 1;
          font-size: 15px;
          font-weight: normal;
          line-height: 1.47;
          @include multi-ellipsis(1);
        }
        .percent {
          margin-left: auto;
          font-size: 13px;
          color: $point;
        }
        .com_chk {
          & + label {
            @include flex(y);
            height: 100%;
          }
        }
      }
    }
    .com_input_upload {
      width: 100%;
      height: 50px;
      margin: 0;
      border: none;
    }
    .btn_upload {
      width: 100%;
      font-size: 14px;
      background: none;
      @include media-max($m) {
        // width: auto;
      }
    }
  }
  .btn_container {
    margin-top: 20px;
    .com_btn {
      height: 50px;
    }
  }
}
// 만족도 체크
.pop_review {
  .list {
    .item {
      height: 50px;
      line-height: 50px;
      background: #f5f7ff;
      border-radius: 5px;
      text-align: center;
      font-size: 15px;
      font-weight: bold;
      letter-spacing: -0.38px;
      color: #222;
      & + .item {
        margin-top: 10px;
      }
    }
  }
}
// 채용관리
.pop_recruit {
  .pop_tit {
    border-bottom: 1px solid $c-border !important;
    margin-inline: 20px;
    margin-bottom: 7px;
  }
  .notice {
    font-weight: bold;
  }
  .detail_txt {
    padding: 20px 0;
    border-top: 1px solid $c-border;
    border-bottom: 1px solid $c-border;
    &.type_noBd {
      border: 0;
      padding: 0;
    }
  }
  .select_list {
    min-height: 220px;
    padding: 11px 11px;
    border: 1px solid #ebe8e8;
    border-radius: 6px;
    overflow-y: auto;
    .select_item {
      & + .select_item {
        margin-top: 5px;
      }
      .rdo_select {
        + label {
          width: 100%;
          height: 35px;
          padding: 10px 15px;
          line-height: 1.1;
          font-size: 14px;
          letter-spacing: -0.35px;
          background-color: $lightBlue;
          &::before {
            display: none;
          }
        }

        &:checked + label {
          background-color: $white;
          border: 1px solid $point;
          color: $point;
        }
      }
    }
  }
  .com_input_sec {
    display: block;
    padding: 10px 0;
    margin-bottom: 20px;
    border-bottom: 1px solid $c-border;
  }
  .btn_container {
    flex-wrap: wrap;
    .btn_suggestion {
      width: 100%;
      flex: auto;
      margin-left: 0 !important;
      margin-top: 10px;
    }
  }
}
// 멘토링 상세
.pop_mentoring_detail {
  .pop_container {
    width: 800px;
    @include media-max($m) {
      width: 90vw;
    }
  }
  .info_bg {
    .info_wrap {
      @include media-max($m) {
        align-items: flex-start;
      }
    }
  }
  .info_wrap {
    margin-top: 15px;
    @include media-max($m) {
      margin-top: 0;
    }
    .info_tit {
      width: 100%;
    }
    .img_logo {
      width: 164px;
      padding: 0;
      border: 0;
      background: none;
      margin-right: 15px;
      @include media-max($m) {
        width: 120px;
      }
    }
    .info_detail {
      @include flex(x);
      margin-top: 15px;
      @include media-max($m) {
        flex-direction: column;
        margin-top: 0px;
      }
      .detail_box {
        width: calc(100% / 3);
        flex: 1;
        font-size: 14px;
        @include media-max($m) {
          width: 100%;
          margin-top: 10px;
          font-size: 13px;
        }
        .tit {
          font-weight: 600;
          color: #999;
        }
        .desc {
          margin-top: 5px;
          @include media-max($m) {
            margin-top: 2px;
          }
        }
      }
      .bar {
        @include divider(1px, 100%, 30px);
        @include media-max($m) {
          display: none;
        }
      }
    }
    .content_tab {
      @include flex(s);
      .tab {
        position: relative;
        padding: 0 10px;
        min-width: 65px;
        height: 50px;
        line-height: 50px;
        font-size: 15px;
        letter-spacing: -0.38;
        text-align: center;
        border-radius: 5px;
        border: solid 1px $point;
        color: $point;
        cursor: pointer;
        @include media-max($xl) {
          // min-width: fit-content;
          // flex: 1;
          font-size: 14px;
          height: 40px;
          line-height: 1.2;
        }
        &:hover,
        &.active {
          background: $point;
          color: $white;
        }
        & + .tab {
          margin-left: 10px;
        }
      }
    }
    .tab_contents {
      margin-top: 10px;
    }
    .tab_box {
      margin-bottom: 10px;
      padding: 20px 20px 40px;
      border-radius: 5px;
      border: solid 1px #e4e5ed;
      background-color: #fff;
      line-height: 1.6;
      @include media-max($m) {
        padding: 15px 15px;
      }
      &.review {
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 400px;
        scrollbar-face-color: #999;
        /*스크롤*/
        scrollbar-highlight-color: #999;
        /*하이라이트*/
        scrollbar-3dlight-color: #999;
        /*왼쪽외곽음영*/
        scrollbar-darkshadow-color: #999;
        /*오른쪽외곽음영*/
        scrollbar-shadow-color: #999;
        /*오른쪽 안쪽음영*/
        scrollbar-arrow-color: #999;
        /*화살*/
        scrollbar-track-color: #999;
        /*스크롤영역*/
        overflow: auto;
        &::-webkit-scrollbar {
          opacity: 0;
          width: 10px;
          height: 10px;
        }
        /*스크롤바*/
        &::-webkit-scrollbar-button {
          width: 0;
          height: 0;
        }

        /*스크롤바 버튼(아래 위 화살표)*/
        &::-webkit-scrollbar-thumb {
          background-color: #999;
          background-clip: padding-box;
          //border: 4px solid transparent;
          //border-radius: 10px;
        }

        /*스크롤바 핸들*/
        &::-webkit-scrollbar-track {
          background-color: #f8f8f8;
        }

        /*스크롤바 진행줄*/
        &::-webkit-scrollbar-track-piece {
          background-color: #f8f8f8;
        }

        /*진행줄에서 핸들로 덮이지 않은 영역 */
        &::-webkit-scrollbar-corner {
          background: #f8f8f8;
        }
      }
      .dot {
        @include divider(4px, 4px, 5px);
        &::after {
          background-color: $point;
          vertical-align: 3px;
          border-radius: 100%;
        }
      }
      .img_wrap {
        @include flex(y);
        flex-wrap: wrap;
        margin-top: 10px;
        margin-left: -10px;
        .img {
          width: calc((100% / 4) - 10px);
          height: 120px;
          margin: 0 0 10px 10px;
          @include media-max($m) {
            height: auto;
            flex: 1;
          }
        }
      }
      &.meeting {
        margin-top: 10px;
        padding: 15px 20px;
        background-color: #f8f8f8;
        @include media-max($m) {
          padding: 15px 15px;
        }
      }
      .card {
        @include flex(x);
        gap: 10px;
        padding-bottom: 20px;
        &:not(:last-child) {
          border-bottom: 1px solid $c-border;
        }
        & + .card {
          padding: 20px 0px;
        }
        .img {
          height: 38px;
          min-width: 38px;
          object-fit: cover;
          border-radius: 100%;
        }
        .info {
          .profile {
            @include flex(between);
            .name {
              font-size: 15px;
              font-weight: 600;
              color: $point;
            }
            .desc {
              font-size: 12px;
              font-weight: 300;
              letter-spacing: -0.3px;
              color: #c4c4c4;
            }
            .star_box {
              position: relative;
              width: 80px;
              height: 16px;
              margin-bottom: 10px;
              display: inline-block;
              background: url("../../img/icon_star_off.png") repeat 0 0;
              background-size: contain;
              .graph {
                position: absolute;
                left: 0;
                top: 0;
                height: 16px;
                background: url("../../img/icon_star_active.png") repeat 0 0;
                background-size: contain;
              }
            }
          }
          .desc_txt {
            margin-top: 10px;
            color: $fc-default;
            font-size: 14px;
          }
        }
      }
    }
  }
  .btn_container {
    margin-top: 20px;
    .com_btn {
      flex: initial;
      min-width: 180px;
    }
  }
  .content_tab.bottom {
    padding-block: 10px 20px;
    border-bottom: 1px solid $c-border;
  }
}
// insight filter
.pop_filter {
  .info_wrap {
    .box {
      padding-bottom: 20px;
      width: inherit;
    }
    @include media-max($m) {
      padding-inline: 0;
    }
    .list {
      @include flex(_);
      gap: 0.5rem;
      flex-wrap: wrap;
      margin-bottom: 0;
      .active {
        color: $point;
        background-color: $white;
        border: 1px solid $point;
      }
      .item {
        margin: 0;
        padding: 0 20px;
        font-weight: normal;
        height: 50px;
        font-size: 13px;
        @include flex(x);
        &.active {
          font-weight: bold;
          border: 2px solid $point;
          background-color: $white;
          color: $point;
        }
        cursor: pointer;
        .label {
          cursor: pointer;
        }
      }
    }
    .input_tit {
      font-size: 15px;
      margin-bottom: 12px;
    }
  }
  .btn_container {
    margin-top: 10px;
    .com_btn {
      height: 50px;
    }
  }
}
// insight share apply
.pop_share {
  .info_wrap {
    .box {
      padding-bottom: 20px;
    }
    .list {
      margin: 0;
    }
    .rule_list {
      @include flex(column);
      gap: 15px;
      .rule {
        text-indent: -14px;
        padding-left: 14px;
        .dot {
          &.blue {
            @include divider(4px, 4px, 5px);
            &::after {
              background-color: $point;
              vertical-align: 3px;
              border-radius: 100%;
            }
          }
        }
      }
    }
    .scroll {
      overflow: auto;
      max-height: 130px;
      &::-webkit-scrollbar {
        width: 10px;
      }
      &::-webkit-scrollbar-track {
        background-color: #f8f8f8;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #999999;
        border-radius: 10px;
      }
    }
    > .com_input_sec {
      margin-block: -25px -14.5px;
      & + .rule_list {
        margin-top: 35px;
      }
    }
  }
}
// agreement popup
.pop_agree {
  .com_term {
    background: none;
    .com_center_wrap {
      padding: 0;
      .pg_tit {
        display: none;
      }
      .box {
        width: 100%;
        padding: 0;
        border: none;
        margin-inline: 0;
      }
    }
  }
}

//!마이페이지>이력서 관리>이력서 완성도란?
.pop_resume {
  .point {
    color: $point;
    margin-bottom: 13px;
  }
  .content_container {
    font-size: 15px;
    text-align: center;
  }
  .dot_list {
    text-align: left;
    margin-top: 20px;
    .item {
      padding: 17px 17px 17px 38px;
      border-radius: 5px;
      background-color: #f5f7ff;
      margin-top: 10px;
      font-size: 15px;
      font-weight: bold;
      line-height: 1.87;
      letter-spacing: -0.38px;
      word-break: break-all;
      &:before {
        @include beforeafter;
        background-color: $point;
        width: 4px;
        height: 4px;
        margin-right: 7px;
        border-radius: 10px;
        vertical-align: 4px;
        margin-left: -11px;
      }
    }
  }
}
//!마이페이지>이력서 관리>희망근무지 추가
.pop_desired_work {
  .list {
    padding-bottom: 22px;
    border-bottom: 1px solid #dfdfdf;
    &:not(:first-child) {
      padding-top: 20px;
    }
  }
}
//!마이페이지>이력서 관리>희망직무추가
.pop_desired_job {
  .box {
    @include flex(column);
    gap: 10px;
    select {
      margin-left: 0;
    }
  }
  /* Custom styling for multiple select */
.custom-multi-select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 150px;
  font-size: 14px;
  background-color: #fff;
  color: #333;

  &:focus {
    outline: none;
    border-color: #007bff;
  }

  & option {
    font-size: 14px;
  }
}

}
//면접제안 상세보기
.pop_interview_proposal {
  .profile_area{
    display: flex;
    align-items: center;
    padding: 20px 0;
    margin-bottom: 20px;
    border-bottom: 1px solid $c-border;
    gap: 8px;
    width: calc(100% - 50px);
    margin-left: 25px;
    .profile{
      margin-right: 12px;
      border-radius: 100%;
      @include thumb(64px, 64px);
      background: url(../../img/img_profile_thumb.png) no-repeat center;
      background-size: 100% auto;
    }
    .com_btn {
      width: 80px;
      min-width: 80px;
    }
  }
  .notice{
    margin-bottom: 20px;
    text-align: left !important;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.25;
    letter-spacing: normal;
    color: #333;
  }
}
//면접제안 상세보기 - 거절
.pop_refuse {
  .pop_tit {
    text-align: center !important;
    border-bottom: none !important;
    margin-bottom: 20px !important;
  }
}
.pop_attachment {
  .pop_tit {
    text-align: center !important;
    border-bottom: none !important;
    margin-bottom: 0 !important;
  }
  .content_container {
    width: 100%;
    padding: 0 20px 15px 20px !important;
    .com_input_sec {
      .tit_field {
        .com_chk {
          &:checked + .circle_radio:before {
            background-image: url('../../img/radio_on.png');
          }
        }
        .circle_radio {
          position: relative;
          width: auto;
          padding-left: 30px;
          &:last-child {
            margin-left: 30px;
          }
          &:before {
            background: url("../../img/radio_off.png") no-repeat;
            width: 20px;
            height: 20px;
            background-size: 20px auto;
            border: none;
            margin-right: 10px;
            vertical-align: -5px;
            border:none;
            position: absolute;
            left: 24px;
            margin-top: -10px;
          }
        }
      }
      .input_field {
        .wrapper {
          @include flex(y);
          gap: 6px;
          .file {
            &::-webkit-file-upload-button {
              display: none;
            }
            padding: 10px 0 10px 14px;
            font-size: 14px;
            border: 1px solid #e1e2e3;
            border-radius: 6px;
          }
        }
      }
    }
  }
}
@import "../common/variables";

.com_mypage {
  background-color: $bg;
  .com_center_wrap {
    padding-block: 25px 113px;
    @include flex(between);
    align-items: inherit;
    @include media-max($xl) {
      padding-inline: $pd-inline;
    }
    @include media-max($l) {
      display: block;
      padding: 0;
    }
  }
  .aside {
    width: 200px;
    border-radius: 20px;
    border: solid 1px #dfdfdf;
    background-color: #fff;
    padding: 18px 16px;
    text-align: center;
    align-self: flex-start;
    @include media-max($l) {
      width: 100%;
      border: none;
      padding: 16px $pd-inline 0;
      text-align: left;
      border-radius: 0;
    }
    .profile_area {
      position: relative;
      width: 90px;
      height: 90px;
      margin-inline: auto;
      margin-bottom: 25px;
      @include media-max($l) {
        float: left;
        margin-right: 20px;
        margin-bottom: 15px;
      }
      .btn_edit {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 20px;
        height: 20px;
        background-color: #e4e5ed;
        border-radius: 100%;
        @include flex(all);
        img {
          width: 11.8px;
        }
      }
      .profile {
        border-radius: 100%;
        @include thumb(90px, 90px);
        background: url(../../img/img_profile_thumb.png) no-repeat center;
        background-size: 100% auto;
      }
    }
    .id_area {
      font-size: 16px;
      font-weight: 500;
      letter-spacing: normal;
      color: $black;
      margin-bottom: 14px;
      @include media-max($l) {
        margin-bottom: 5px;
        font-size: 14px;
      }
      .com_btn {
        width: 40px;
        height: 20px;
        min-height: 20px;
        min-width: auto;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: normal;
        text-align: center;
        color: #333;
        padding: 0;
        margin-left: 5px;
      }
    }
    .link_area {
      margin-bottom: 17px;
      @include media-max($l) {
        margin-bottom: 10px;
      }
      .link {
        color: $point;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: normal;
        &:not(:last-child) {
          @include divider(1px, 10px, 10px);
        }
      }
    }
    .com_btn_wrap {
      @include flex(all);
      flex-wrap: wrap;
      margin-block: 10px 30px;
      gap: 3px;
      @include media-max($l) {
        position: relative;
        margin-bottom: 20px;
        @include flex(between);
        flex-wrap: nowrap;
      }
      .com_btn_tip {
        margin-left: 5px;
        min-width: 15px;
        @include media-max($m) {
          margin-left: 2px;
        }
      }
      .com_btn {
        flex: initial;
        width: auto;
        margin-left: 0;
      }
      .btn_my,
      .btn_account {
        margin-bottom: 7px;
        min-width: 70px;
        padding: 0 9px;
        font-size: 13px;
        font-weight: 500;
        line-height: 1.15;
        @include media-max($l) {
          margin-bottom: 0;
          font-size: 12px;
        }
      }
      .btn_account {
        @include media-max($l) {
          position: absolute;
          top: -27px;
          right: 0;
          background: none;
          color: $point;
          font-size: 12px;
          padding: 0;
          height: auto;
        }
      }
      .btn_apply {
        min-width: 143px;
        @include media-max($l) {
          min-width: 110px;
          padding: 0;
        }
      }
      @include media-max($l) {
        justify-content: flex-start;
        .com_btn {
          width: auto;
          flex: inherit;
        }
      }
    }
    .point_area {
      width: calc(100% + 32px);
      margin-left: -16px;
      margin-bottom: 12px;
      @include media-max($l) {
        @include flex(between);
        width: calc(100% + $pd-inline * 2);
        margin-left: calc(0px - $pd-inline);
        margin-bottom: 0;
      }
      .item {
        background-color: #f5f5f9;
        border-bottom: 1px solid #fff;
        @include flex(between);
        padding: 16px 14px;
        font-size: 14px;
        font-weight: 500;
        text-align: left;
        @include media-max($l) {
          flex: 1;
          border-bottom: none;
          font-size: 13px;
          padding: 15px $pd-inline;
          &:not(:last-child) {
            border-right: 1px solid #fff;
          }
        }
        .com_btn {
          margin-left: 10px;
        }
        .num {
          margin-left: auto;
          color: $point;
          font-size: 14px;
          font-weight: bold;
          text-align: right;
        }
      }
    }
    .selected_menu {
      padding: 17px $pd-inline;
      border-bottom: 1px solid $c-border;
      width: calc(100% + $pd-inline * 2);
      margin-left: calc(0px - $pd-inline);
      color: $point;
      font-size: 14px;
      @include flex(between);
      &:after {
        @include addImg(lnb_active, 5, 10);
        transform: rotate(90deg);
      }
      @include media-min($l) {
        display: none;
      }
    }
    .lnb {
      @include media-max($l) {
        position: absolute;
        background: #fff;
        width: 100%;
        left: 0;
        display: none;
        z-index: 9;
        &.active {
          display: block;
        }
      }
      .menu {
        @include flex(between);
        padding-block: 12px;
        @include media-max($l) {
          padding-inline: $pd-inline;
          border-bottom: 1px solid $c-border;
        }
        @include media-max($m) {
          font-size: 14px;
        }
        &.active {
          color: $point;
          &:after {
            @include addImg(lnb_active, 5, 10);
            @include media-max($l) {
              display: none;
            }
          }
        }
      }
    }
  }
  .container {
    border-radius: 20px;
    border: solid 1px #dfdfdf;
    background-color: #fff;
    padding: 30px;
    width: calc(100% - 230px);
    position: relative;
    @include media-max($l) {
      padding: 23px $pd-inline;
      width: 100%;
      border-radius: 0;
      border: none;
    }
    .com_pg_tit {
      font-size: 24px;
      margin-bottom: 21px;
      @include media-max($l) {
        font-size: 18px;
      }
    }
  }
}

//!환전내역
.p_exchange {
  .sec_point {
    padding: 20px;
    border-radius: 10px;
    border: solid 1px #dfdfdf;
    background-color: #f5f5f9;
    @include flex(between);
    margin-bottom: 30px;
    @include media-max($m) {
      margin-bottom: 20px;
      @include flex-wrap;
      align-items: inherit;
      padding: 0;
    }
    .item {
      width: 25%;
      text-align: center;
      &:not(:first-child) {
        border-left: 1px solid $c-border;
      }
      @include media-max($m) {
        width: 50%;
        border-bottom: 1px solid $c-border;
        padding: 17px $pd-inline;
        text-align: left;
        &:last-child {
          border-bottom: none;
        }
        &:nth-last-child(2) {
          border-bottom: none;
        }
        &:nth-child(2n + 1) {
          border-left: none;
        }
      }
      .tit {
        font-size: 14px;
        letter-spacing: normal;
        margin-bottom: 6px;
      }
      .num {
        font-size: 18px;
        font-weight: bold;
        line-height: 1.67;
        letter-spacing: normal;
        color: $point;
      }
      .com_btn {
        min-width: auto;
        margin-left: 10px;
      }
    }
  }
}

//!환전계좌관리
.p_account {
  .select {
    width: 360px;
    @include media-max($m) {
      width: 100%;
    }
  }
  .input {
    width: 100%;
  }
}

//!환전신청
.p_exchange_application {
  .block_m {
    @include media-max($m) {
      display: block;
      margin-top: 9px;
      word-break: break-all;
    }
  }
}

//!알림
.p_push {
  .tit_field {
    text-align: left;
    @include media-min($m) {
      padding-left: 30px;
    }
  }
  .checked {
    .tit_field {
      color: #cfcfcf !important;
    }
    color: #cfcfcf !important;
  }
}

//!고객센터>faq
.p_faq {
  .tit_field {
    text-align: left;
  }
  .com_board_list {
    tr {
      .state_field {
        @include media-max($m) {
          order: 1;
          margin-top: 0;
          color: $point;
          font-weight: 600;
          margin-bottom: 10px;
        }
      }
      &.a {
        background-color: #f6f6f6;
        td {
          text-align: left;
          padding-inline: $pd-inline;
          @include media-max($m) {
            padding-inline: 0;
          }
        }
      }
    }
  }
}

//!1:1문의
.p_inquiry {
  .sec_agree {
    text-align: center;
    margin-top: 26px;
    .com_chk {
      & + label {
        font-weight: 600;
        font-size: 14px;
        @include flex(all);
        &:before {
          width: 25px;
          height: 25px;
          background-size: 12px auto;
        }
      }
    }
  }
}
//!상품관리
.p_myproduct {
  .com_board_list {
    @include media-max($m) {
      tr {
        :is(.state_field, .id_field) {
          order: 1;
          color: $point;
          font-weight: bold;
          width: auto;
          margin-top: 0;
        }
        .date_field {
          order: 1;
        }
        .tit_field {
          order: 2;
          margin-top: 10px;
        }
        .btn_field {
          min-width: auto;
        }
        .accept_field {
          order: 10;
          margin-left: 5px;
          margin-top: 10px;
          .txt {
            margin-left: 14.5px;
            font-weight: bold;
            color: #666;
          }
        }
      }
    }
    .accept_field {
      .com_btn {
        margin-inline: 3px;
      }
    }
  }
}

//!My커뮤니티
.p_mycommunity {
  .com_community_card {
    padding: 22.5px 0;
    @include media-max($m) {
      padding: 20px $pd-inline;
    }
    .info {
      .profile {
        margin-bottom: 14px;
        @include flex(y);
        .desc {
          font-size: 12px;
          margin: 0;
          padding: 0;
        }
      }
      .name {
        font-size: 14px;
        margin-bottom: 0;
      }
      .tit {
        font-size: 14px;
      }
      .description {
        font-size: 14px;
        margin-bottom: 17px;
        font-weight: normal;
        @include media-max($m) {
          font-size: 13px;
        }
      }
    }
  }
  .com_paging {
    margin-top: 20px;
  }
  .wrap {
    margin-top: 30px;
    border-top: 1px solid $c-border;
    border-bottom: 1px solid $c-border;
    @include media-max($m) {
      margin-top: 20px;
      border: 1px solid $c-border;
      border-radius: 5px;
    }
  }
}
//!세미나 신청내역
.p_myseminar {
  .com_board_list {
    .block_pc {
      @include media-min($m) {
        display: block;
      }
    }
  }
}
//!결제내역
.p_paylist {
  @include media-max($m) {
    .com_board_list {
      .state_field {
        width: 50%;
        margin-top: 10px;
      }
      .btn_field {
        margin-left: auto;
        text-align: right;
      }
    }
  }
}
//!결제 콘텐츠 목록>결제 인사이트 목록
.p_pay_insight {
  .com_board_list {
    @include media-max($m) {
      .date_field {
        order: 1;
        width: 50%;
      }
      .state_field {
        order: 1;
        color: $point;
        font-weight: bold;
        width: 50%;
        margin-top: 0;
      }
      .tit_field {
        width: 100%;
        order: 2;
        margin-top: 10px;
      }
      .chk_field {
        margin-top: 0;
      }
    }
  }
}
//!결제콘텐츠목록>결제멘토링목록
.p_pay_mentoring {
  .com_board_list {
    @include media-min($m) {
      .channel_field {
        overflow: hidden;
        .com_btn {
          float: right;
          width: calc((100% - 5px) / 2);
          &.btn_meeting {
            flex-direction: column;
            float: left;
            height: 65px;
            border-radius: 10px;
            margin-right: 5px;
          }
          &.btn_qna {
            margin-top: 5px;
          }
        }
      }
    }
    @include media-max($m) {
      .state_field {
        order: 11;
        width: 50%;
        text-align: right;
      }
      .btn_field {
        width: 50%;
      }
      .channel_field {
        width: 100%;
        order: 9;
        margin-top: 10px;
        @include flex(y);
        .com_btn {
          margin: 0;
          & + .com_btn {
            margin-left: 5px;
          }
        }
      }
    }
  }
}
//!화상미팅
.p_webcam {
  .com_pg_tit {
    border-bottom: 1px solid $c-border;
    padding-bottom: 20px;
  }
  .sec_webcame {
    aspect-ratio: 91/55;
    background: #666;
  }
}
//!질문과 답변 게시판
.p_qna {
  .com_board_list {
    .tit_field {
      text-align: left;
    }
    @include media-max($m) {
      .id_field {
        order: 1;
        font-size: 13px;
        font-weight: 600;
        letter-spacing: normal;
        color: $point;
      }
      .tit_field {
        order: 5;
        margin-block: 10px 0;
      }
    }
  }
}
.p_qna_write {
  .container {
    .com_pg_tit {
      margin-bottom: 36px;
      @include media-max($m) {
        margin-bottom: 20px;
      }
    }
  }
}
//!결제컨텐츠 목록>질문과답변>상세
.p_qna_view {
  &.com_detail.type_community {
    .container {
      background: none;
      border: none;
      padding: 0;
    }
    .com_center_wrap {
      @include media-max($xl) {
        flex-direction: row;
      }
    }
    .sec_detail {
      gap: 0;
      @include media-max($xl) {
        max-width: 100%;
      }
      .title_wrap {
        border-radius: 30px 30px 0 0;
        border-bottom: none;
        padding-bottom: 0;
        @include media-max($l) {
          border-radius: 0;
          border: none;
        }
        @include media-max($m) {
          padding: 20px $pd-inline 0;
        }
        .heading_wrap {
          border-bottom: 1px solid $c-border;
          padding-bottom: 27px;
          @include media-max($m) {
            position: relative;
            padding-bottom: 18px;
            .header {
              .user {
                width: calc(100% - 100px);
              }
            }
          }
          .like_wrap {
            gap: 5px;
            align-self: flex-end;
            @include media-max($m) {
              position: absolute;
              bottom: 18px;
              right: 0;
              text-align: right;
              justify-content: flex-end;
            }
          }
        }
      }
      .description_wrap {
        border-top: none;
        border-radius: 0 0 30px 30px;
        @include media-max($l) {
          border: none;
          border-radius: 0;
        }
        @include media-max($m) {
          padding: 20px $pd-inline 0;
        }
      }
      .comment_box_wrap {
        margin-top: 10px;
        padding-inline: 30px;
        padding-bottom: 25px;
        border-radius: 30px;
        @include media-max($l) {
          margin-top: 0;
          border-radius: 0;
          border: none;
        }
        @include media-max($m) {
          padding-inline: $pd-inline;
        }
        .comment_input_box {
          padding: 24px 0 19px;
          .comment {
            font-size: 14px;
            &:before {
              @include addImg(icon_comment, 22, 22);
            }
            @include media-max($m) {
              margin-bottom: 0;
            }
          }
          & ~ .comment_input_box {
            border-bottom: none;
            padding-bottom: 10px;
          }
          & + .comment_input_box {
            padding-top: 0;
          }
          &.file {
            flex-wrap: wrap;
            .com_btn_file {
              &:after {
                @include addImg(icon_close, 16, 16);
              }
            }
          }
        }
        .comment_wrap {
          padding: 17px 0;
          .file {
            margin-top: 10px;
            @include flex(y);
            @include flex-wrap;
          }
        }
      }
    }
  }
}
//!상품관리>인사이트상품
.p_product_insight {
  @include media-max($m) {
    .com_board_list {
      .state_field {
        color: $point;
        font-weight: bold;
        order: 1;
        margin-top: 0;
        width: 50%;
      }
      .date_field {
        order: 2;
      }
      .tit_field {
        order: 3;
        margin-top: 10px;
      }
    }
  }
}
//!내 정보 수정
.p_profile {
  .btn_wrap {
    @include flex(all);
    margin-top: 25px;
    @include media-max($m) {
      flex-direction: column-reverse;
      align-items: center;
    }
    .com_btn_wrap {
      margin-top: 0;
    }
  }
  .btn_leave {
    font-size: 14px;
    line-height: 1;
    letter-spacing: normal;
    color: #999;
    text-decoration: underline;
    position: absolute;
    right: 30px;
    @include media-max($m) {
      position: relative;
      margin-left: auto;
      right: 0;
      margin-bottom: 20px;
    }
    &:after {
      content: ">";
      display: inline-block;
      vertical-align: middle;
      width: 10px;
      height: 18px;
      margin-left: 3px;
    }
  }
}
//!회원탈퇴
.p_leave {
  .sec_notice {
    padding: 30px 30px;
    border-radius: 10px;
    border: solid 1px #dfdfdf;
    background-color: #f5f5f9;
    @include flex(y);
    font-size: 14px;
    line-height: 1.43;
    @include media-max($m) {
      padding: 15px;
    }
    &::before {
      content: "";
      display: inline-block;
      width: 70px;
      height: 70px;
      background: url("../../img/icon_notice.png") no-repeat 0 0;
      background-size: contain;
      margin-right: 7px;
      @include media-max($m) {
        display: none;
      }
    }
    .notice_item {
      @include flex(s);
      font-size: 14px;
      line-height: 1.5;
      & + .notice_item {
        margin-top: 10px;
      }
      .dot {
        @include divider(2px, 2px, 5px);
        &::after {
          vertical-align: 3px;
          background: #333;
        }
      }
    }
  }
  .com_input_sec {
    margin-top: 26px;
    &.type_row {
      .field_box {
        .input {
          min-width: 170px;
          width: 170px;
        }
      }
    }
  }
}
//!포인트충전, 환전 계좌관리
.p_exchange_account,
.p_point_charge,
.p_job_search_apply {
  .container {
    .com_pg_tit {
      @include media-max($m) {
        margin-bottom: 7px;
      }
    }
    .sub_desc {
      font-size: 14px;
      line-height: 1.2;
      margin-bottom: 33px;
      @include media-max($m) {
        font-size: 13px;
        margin-bottom: 20px;
      }
    }
  }
}
//!환전 계좌관리
.p_point_charge {
  .list {
    @include flex(s);
    @include flex-wrap(wrap);
    .item {
      width: calc(100% / 5);
      @include flex(y);
      @include media-max($m) {
        width: calc(100% / 3);
      }
      & + .item {
        margin-bottom: 15px;
      }
      .input {
        min-width: 170px;
        width: 170px;
        margin-left: 7px;
        margin-right: 7px;
      }
      &.last {
        width: 100%;
      }
    }
  }
  .sec_notice {
    margin-top: 25px;
    padding: 30px 30px;
    border-radius: 10px;
    border: solid 1px #dfdfdf;
    background-color: #f5f5f9;
    @include flex(y);
    font-size: 14px;
    line-height: 1.43;
    @include media-max($m) {
      padding: 15px;
    }
    &::before {
      content: "";
      display: inline-block;
      width: 70px;
      height: 70px;
      padding: 20px 20px;
      border-radius: 100%;
      background: #fff url("../../img/icon_point_notice.png") no-repeat center;
      background-size: 44px 42px;
      margin-right: 7px;
      @include flex(all);
      @include media-max($m) {
        display: none;
      }
    }
    .notice_item {
      @include flex(s);
      font-size: 14px;
      line-height: 1.5;
      & + .notice_item {
        margin-top: 10px;
      }
      .dot {
        @include divider(2px, 2px, 5px);
        &::after {
          vertical-align: 3px;
          background: #333;
        }
      }
    }
  }
  .agree_chk_wrap {
    margin: 25px 0 22px;
    text-align: center;
  }
}
//search job
.p_job_search_apply {
  .sec_agree {
    padding: 30px 30px;
    border-radius: 10px;
    border: solid 1px #dfdfdf;
    background-color: #f5f5f9;
    height: 510px;
  }
  .agree_chk_wrap {
    margin: 25px 0 22px;
    text-align: center;
  }
}
//!나의성향
.p_myFit {
  .container {
    .com_pg_tit {
      @include media-max($m) {
        margin-bottom: 10px;
      }
      .notice {
        display: inline-block;
        margin-left: 7px;
        font-size: 14px;
        font-weight: bold;
        line-height: 2.14;
        letter-spacing: normal;
        color: $point;
        @include media-max($m) {
          display: block;
          margin-top: 2px;
          margin-left: 0;
          font-size: 13px;
          font-weight: normal;
        }
      }
    }
  }
  .sec_survey {
    position: relative;
    margin-top: 20px;
    padding: 23px 30px 50px;
    border-radius: 10px;
    border: solid 1px #dfdfdf;
    background-color: #f5f5f9;
    @include media-max($m) {
      padding: 14px 16px 20px;
    }
    .tit {
      margin-bottom: 10px;
      font-size: 24px;
      font-weight: 600;
      line-height: 1.75;
      letter-spacing: normal;
      text-align: center;
      color: #000;
      @include media-max($m) {
        font-size: 18px;
      }
    }
    .q_wrap {
      & + .q_wrap {
        margin-top: 20px;
      }
      .q_tit {
        margin-top: 40px;
        margin-bottom: 20px;
        font-size: 20px;
        font-weight: bold;
        line-height: 1.5;
        text-align: center;
        @include media-max($m) {
          margin-top: 30px;
          margin-bottom: 15px;
          font-size: 14px;
        }
      }
      .q_list {
        width: 360px;
        margin: 0 auto;
        @include media-max($m) {
          width: 100%;
        }
      }
      .q_item {
        line-height: 50px;
        background: #fff;
        border-radius: 5px;
        text-align: center;
        font-size: 15px;
        font-weight: bold;
        letter-spacing: -0.38px;
        text-align: center;
        color: #222;

        transition:
          background-color 0.2s,
          transform 0.2s;

        &:hover {
          background-color: #8096f1;
          transform: scale(1.05);
        }

        & + .q_item {
          margin-top: 10px;
        }
      }
      .com_chk {
        & + label {
          position: relative;
          display: block;
          width: 100%;
          height: 100%;
          border: 2px solid #fff;
          // padding-left: 0;
          border-radius: 5px;
          transition: border-color 0.2s;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 14px 25px 14px 25px;
          &::before {
            display: none;
          }
          &::after {
            content: "";
            display: inline-block;
            background: url(../../img/arrow_right_blue.png) no-repeat 0 0;
            background-size: contain;
            width: 14px;
            height: 18px;
            position: absolute;
            right: 10px;
            top: 14px;
          }
        }
        &:checked + label {
          border: 2px solid $point;
          color: $point;
        }
      }
      .tag_tit {
        font-size: 20px;
        font-weight: bold;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        margin-bottom: 20px;
      }
      .com_tag_list {
        @include flex(all);
        @include flex-wrap(wrap);
        max-width: 580px;
        margin: 0 auto;
      }
    }
    .notice {
      font-size: 14px;
      line-height: 1.43;
      text-align: center;
      position: absolute;
      z-index: 1;
      top: 110px;
      left: 0;
      width: 100%;
      @include media-max($m) {
        font-size: 13px;
        top: 90px;
      }
    }
    .swiper_wrap {
      &.jobFit {
        .q_wrap {
          &:first-child {
            .q_tit {
              margin-top: 60px;
            }
          }
        }
      }
    }
  }
  .swiper_wrap {
    position: relative;
    .custom_pagination {
      position: relative;
      margin-top: 10px;
      .swiper-scrollbar {
        top: 10px;
      }
      .swiper-button-prev {
        left: 0;
        top: 30px;
        width: 15px;
        height: 15px;
        &::after {
          font-size: 12px;
        }
      }
    }
    .swiper-scrollbar {
      width: 90%;
      height: 10px;
      border-radius: 30px;
      bottom: auto;
      top: 0;
      left: 20px;
      background-color: #fff;
      @include media-max($xl) {
        // width: 74%;
      }
      @include media-max($m) {
        width: 80%;
      }
      .swiper-scrollbar-drag {
        background: $point;
      }
    }
    .swiper_fraction {
      right: 0;
      top: 10px;
      bottom: auto;
      position: absolute;
      text-align: right;
      font-size: 14px;
      font-weight: 600;
      line-height: 0.71;
      letter-spacing: normal;
      color: $point;
    }
  }
}
//!Ai매칭 지원 현황
//^탭 tab
.com_ai_tab {
  @include flex(y);
  padding: 20px 30px;
  border-radius: 10px;
  border: solid 1px #dfdfdf;
  background-color: #f5f5f9;
  margin-bottom: 30px;
  gap: 10px;
  @include media-max($m) {
    margin-bottom: 20px;
  }
  @include media-max($xl) {
    padding: 20px;
    gap: 5px;
  }
  .tab {
    padding: 18px 20px;
    border-radius: 5px;
    background-color: #fff;
    border: solid 2px #fff;
    flex: 1;
    @include flex(between);
    @include media-max($xl) {
      flex-direction: column;
      padding: 10px 0;
    }
    .txt {
      font-size: 14px;
      letter-spacing: -0.35px;
      @include media-max($m) {
        font-size: 12px;
      }
    }
    .num {
      font-size: 20px;
      font-weight: bold;
      letter-spacing: -0.5px;
      color: $point;
      @include media-max($m) {
        font-size: 14px;
      }
    }
    &.active {
      border-color: $point;
      .txt {
        color: $point;
      }
    }
  }
}
//^공통 common
.com_my_match {
  .com_board_list {
    .btn_field {
      .com_btn {
        & + .com_btn {
          margin-left: 7px;
        }
      }
    }
    @include media-max($m) {
      .company_field {
        order: 1;
        font-size: 13px;
        font-weight: bold;
        color: $point;
        @include divider(2px, 2px, 5px);
        @include flex(y);
      }
      .type_field {
        order: 2;
      }
      .date_field {
        order: 3;
      }
      .tit_field {
        order: 4;
        margin-block: 10px 0;
      }
      .info_field {
        order: 5;
        width: 100%;
        margin-top: 10px;
      }
      .date_field + .state_field {
        order: 10;
        width: 50%;
        text-align: right;
      }
      .state_field {
        margin-top: 10px;
      }
    }
  }
  .com_board_search {
    @include media-max($xl) {
      flex-wrap: wrap;
      .search {
        margin-top: 20px;
        padding-top: 20px;
        border-top: 1px solid $c-border;
        &:before {
          display: none;
        }
      }
    }
  }
}
//^AI매칭 지원현황>찜한공고
.p_match_wish_company {
  .list {
    margin-bottom: 30px;
    @include list(10px, 3, 20px);
    @include media-max($m) {
      @include list(0, 1, 20px);
    }
    .com_job_item {
      background: none;
      .graph {
        bottom: 90px;
        @include media-max($m) {
          bottom: 25px;
        }
      }
      @include media-max($m) {
        .tit {
          font-size: 14px;
        }
        .info {
          font-size: 13px;
          .info_tit {
            font-size: 13px;
          }
        }
        .info_s {
          font-size: 13px;
        }
        .tag_list {
          font-size: 13px;
          margin-top: 10px;
        }
      }
    }
  }
}
// attachment
.p_attachment {
  .input_description_box {
    .input_description {
      text-align: center;
      min-height: 120px;
      margin: 20px 0 18px;
      font-size: 14px;
      font-weight: 600;
    }
    .btn_wrapper {
      @include flex(y);
      justify-content: flex-end;
      @include media-max($m) {
        @include flex(all);
      }
      .com_btn {
        min-width: 100px;
        font-size: 14px;
        font-weight: 500;
        padding: 7px 24px;
        border-radius: 30px;
        margin-bottom: 12px;
      }
    }
  }
  .com_board_list {
    @include media-max($m) {
      .date_field {
        text-align: left;
        width: 100%;
        margin-bottom: 10px;
      }
      .btn_field {
        width: 100%;
      }
      .type_field {
        order: 2;
        color: #999;
        @include divider(1px, 10px, 10px);
      }
    }
  }
}

//&이력서 완성도
.com_resume_progress {
  @include flex(column);
  align-items: center;
  gap: 10px;
  padding: 24px 55px 20px;
  margin-block: 20px 17px;
  border-radius: 10px;
  border: 1px solid #dfdfdf;
  background-color: #f5f5f9;
  @include media-max($m) {
    padding: 20px;
  }
  .tit {
    font-size: 14px;
    font-weight: 600;
  }
  .progress_bar {
    width: 100%;
    @include flex(y);
    gap: 12px;
    &.text_blue {
      color: $point;
    }
    .progress,
    .progress .filled {
      display: block;
      width: 100%;
      height: 10px;
      background: $white;
      border-radius: 30px;
      .filled {
        background: $point;
        width: 70%;
      }
    }
  }
  .com_btn {
    padding: 7px 25px;
    font-size: 14px;
    border-radius: 50px;
    min-width: max-content;
  }
}

.com_myresume {
  .input_description_box {
    .btn_wrapper {
      @include flex(y);
      justify-content: flex-end;
      @include media-max($m) {
        @include flex(all);
      }
      .com_btn {
        min-width: 100px;
        font-size: 14px;
        font-weight: 500;
        padding: 7px 24px;
        border-radius: 30px;
        margin-bottom: 12px;
        @include media-max($m) {
          margin-bottom: 20px;
        }
      }
    }
  }
}

//!이력서 관리
.p_resume {
  .com_board_list {
    .btn_field {
      .com_btn {
        & + .com_btn {
          margin-left: 7px;
        }
      }
    }
    @include media-max($m) {
      .date_field {
        order: 1;
        text-align: left;
        margin: 0;
        width: 50%;
      }
      .complete_field {
        font-size: 13px;
        width: 50%;
        text-align: end;
        color: $point;
        font-weight: 600;
        order: 2;
        &::before {
          content: "완성도 : ";
        }
      }
      .tit_field {
        order: 3;
        margin-top: 10px;
        margin-bottom: 10px;
      }
      .state_field {
        width: auto;
        margin-top: 0;
      }
      .btn_field {
        margin-top: 0;
        margin-left: 7px;
      }
    }
  }
}

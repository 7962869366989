//! button
.com_btn {
  min-width: 190px;
  padding: 16px 10px;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  letter-spacing: normal;
  box-sizing: border-box;
  &_wrap {
    margin-top: 30px;
    @include flex(between);
    .com_btn {
      width: 100%;
      min-width: auto;
      flex: 1;
      & + .com_btn {
        margin-left: 10px;
      }
    }
    &.center {
      @include flex(all);
      .com_btn {
        width: auto;
        min-width: 190px;
        flex: initial;
        &.s {
          min-width: 110px;
        }
      }
      @include media-max($m) {
        .com_btn {
          min-width: 135px;
        }
      }
      &.full {
        .com_btn {
          @include media-max($m) {
            flex: 1;
          }
        }
      }
    }
  }
  //&컬러 color
  &.blue {
    background: $point;
    color: $white;
  }
  &.black {
    background: #111;
    color: $white;
  }
  &.white {
    background: #fff;
    color: $fc-default;
    border: 1px solid #999;
  }
  &.gray {
    background: #999;
    color: #fff;
  }
  &.lightgray {
    background-color: #e4e5ed;
    color: #666;
  }
  &.green {
    background: #36ac29;
  }
  &.lightblue {
    background-color: #f5f7ff;
    color: #222;
  }
  //&유형 type
  &.line {
    background: $white;
    border-color: $point;
    border: 1px solid $point;
    color: $point;
    &.white {
      border-color: $white;
    }
    &.gray {
      border-color: #9d9d9d;
      color: #666666;
    }
    &.black {
      border-color: #1c1c1c;
      color: #1c1c1c;
    }
    &.b {
      border-width: 2px;
    }
  }
  &.txt {
    min-width: auto;
    padding: 0;
    font-size: 12px;
    font-weight: normal;
    letter-spacing: -0.6px;
    color: #666;
    text-decoration: underline;
    text-underline-offset: 1px;
  }
  //&사이즈 size
  &.l {
    height: 60px;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.4;
    @include media-max($xl) {
      height: 50px;
      font-size: 16px;
    }
  }
  &.m {
    height: 50px;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.4;
    @include media-max($xl) {
      height: 40px;
      font-size: 15px;
    }
  }
  &.s {
    height: 40px;
    font-size: 14px;
    font-weight: bold;
    line-height: 1;
    letter-spacing: normal;
    @include media-max($xl) {
      height: 36px;
      font-size: 12px;
    }
  }
  &.xs {
    height: 30px;
    padding: 0 10px;
    font-size: 14px;
    @include media-max($xl) {
      font-size: 12px;
    }
  }
  &.xxs {
    height: 20px;
    font-size: 12px;
    min-width: auto;
    padding: 0 10px;
  }
  &.wl {
    width: 60px;
    @include media-max($xl) {
      width: 40px;
    }
  }
  &.oval {
    border-radius: 30px;
  }
  &.icon_arrow {
    &::after {
      content: "";
      background-image: url(../../img/icon_btn_arrow_blue.png);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: right;
      width: 17px;
      height: 11px;
      margin-left: 20px;
    }
  }
  &_tip {
    width: 15px;
    height: 15px;
    border: solid 1px #c4c4c4;
    background-color: #dfdfdf;
    border-radius: 100px;
    @include flex(all);
    font-size: 11px;
    font-weight: 600;
    letter-spacing: normal;
    color: #666;
  }
  &_file {
    height: 36px;
    padding-inline: 19px;
    border-radius: 5px;
    background-color: $bg;
    font-size: 14px;
    letter-spacing: -0.35px;
    color: #222;
    margin-right: 10px;
    margin-bottom: 5px;
    @include flex(all);
    &:after {
      @include addImg(icon_down, 16, 16);
      margin-left: 10px;
    }
  }
  &_top {
    //& go up button
    position: fixed;
    bottom: 30px;
    left: 50%;
    margin-left: 550px;
    z-index: 1;
    @include media-max($xl) {
      margin-left: -22px;
    }
    .btn_top {
      width: 46px;
      height: 46px;
      @include border-radius(100%);
      box-shadow: 0px 10px 20px 0 rgba(0, 0, 0, 0.1);
    }
    .btn_back {
      width: auto;
      height: auto;
      padding: 10px 20px;
      // @include border-radius(50%);
      box-shadow: 0px 10px 20px 0 rgba(0, 0, 0, 0.1);
    }
  }
}

//!paging 페이징
.com_paging {
  text-align: center;
  @include flex(all);
  @include media-max($xl) {
    margin-bottom: -10px;
  }
  :is(.btn_first, .btn_prev, .btn_next, .btn_last) {
    border-radius: 100%;
    min-width: 38px;
    height: 38px;
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    margin-left: 7px;
    box-shadow: 0 0 20px 0 rgba(102, 102, 102, 0.2);
    background-color: #fff;
    @include media-max($xl) {
      height: 34px;
      min-width: 34px;
      margin-left: 5px;
    }
  }
  :is(.btn_first, .btn_prev, .btn_next, .btn_last) img {
    height: 15px;
  }
  // :is(.btn_first, .btn_prev) {
    // transform: rotate(180deg);
  // }
  .btn_prev {
    margin-right: 22px;
    @include media-max($xl) {
      margin-right: 17px;
    }
  }
  .btn_next {
    margin-left: 17px;
    box-shadow: 0 0 20px 0 rgba(0, 53, 255, 0.3);
  }
  .btn_page {
    font-family: "scdream", Sans-serif, "Malgun Gothic", "맑은 고딕", Roboto, Arial;
    line-height: inherit;
    font-size: 16px;
    font-weight: 800;
    line-height: 1.75;
    letter-spacing: normal;
    color: #999;
    margin-inline: 5px;
    @include media-max($xl) {
      font-size: 14px;
    }
    &.active {
      color: #000;
    }
    &.disabled {
      cursor: auto;
      opacity: 0.4;
    }
  }
}
// 관리자, 개발자 요청으로 페이징 스타일 추가
.sec-paging {
  overflow: hidden;
  text-align: center;
  margin-top: 40px;
  @include media-max($xl) {
    margin-bottom: -10px;
    margin-top: 20px;
  }
  :is(.pg-btn, .btn-page) {
    border: 1px solid #e4e4e4;
    background: #fff;
    border-radius: 100%;
    min-width: 38px;
    height: 38px;
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    margin-left: 7px;
    cursor: pointer;
    @include media-max($xl) {
      height: 28px;
      min-width: 28px;
      margin-left: 5px;
      margin-bottom: 10px;
    }
  }
  .pg-btn {
    background-image: url("../../img/arrow_left.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 22px;
    text-indent: -9999px;
    @include media-max($xl) {
      background-size: 18px;
    }
    &.disabled {
      cursor: auto;
      opacity: 0.4;
    }
  }
  .group-prev {
    background-image: url("../../img/arrow_left.png");
    transform: rotate(180deg);
  }
  .group-next {
    background-image: url("../../img/arrow_left.png");
  }
  :is(.group-prev, .prev) {
    transform: rotate(180deg);
  }
  .group-prev {
    // margin-right: 22px;
    @include media-max($xl) {
      margin-right: 10px;
    }
  }
  .group-next {
    // margin-left: 29px;
    @include media-max($xl) {
      margin-left: 15px;
    }
  }
  .btn-page {
    font-family: "scdream", Sans-serif, "Malgun Gothic", "맑은 고딕", Roboto, Arial;
    line-height: inherit;
    @include media-max($xl) {
      font-size: 13px;
    }
    &.active {
      background: $point;
      color: #fff;
      border: none;
    }
  }
}

//!탭
.com_tab {
  @include flex(all);
  border-bottom: solid 1px #dedede;
  .tab {
    @include flex(all);
    flex: 1;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    color: #333;
    padding-block: 17px;
    &.active {
      border-bottom: 3px solid $point;
      margin-bottom: -2px;
    }
  }
}

.com_pg_tab {
  border-radius: 30px;
  background-color: #e4e5ed;
  width: auto;
  display: inline-block;
  @include media-max($m) {
    width: 100%;
    @include flex(all);
    margin-bottom: 20px;
  }
  .tab {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: center;
    color: #1c1c1c;
    padding-block: 12px;
    text-align: center;
    min-width: 200px;
    @include media-max($m) {
      min-width: auto;
      flex: 1;
    }
    &.active {
      color: #fff;
      background-color: $point;
      border-radius: 30px;
    }
  }
}

.com_cate {
  @include media-max($m) {
    text-align: center;
    margin-bottom: 20px;
  }
  .cate {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.14;
    letter-spacing: normal;

    &:not(:last-child) {
      @include divider(1px, 10px, 10px);
    }
    &.active {
      color: $point;
    }
  }
}

//! sub page common tab
.com_tab_wrap {
  margin: 40px 0 45px;
  @include flex(between);
  @include flex(y);
  @include media-max($m) {
    margin: 25px 0;
    @include flex(column);
    align-items: flex-start;
  }
  .content_tab {
    @include flex(s);
    @include media-max($m) {
      justify-content: flex-start;
    }
    .tab {
      position: relative;
      padding: 0 10px;
      min-width: 100px;
      height: 32px;
      font-size: 18px;
      font-weight: bold;
      line-height: 1.17;
      letter-spacing: normal;
      text-align: center;
      cursor: pointer;
      @include media-max($xl) {
        min-width: fit-content;
        font-size: 14px;
        padding: 0;
        height: 28px;
      }
      &:hover,
      &.active {
        border-bottom: solid 3px $point;
      }
      & + .tab {
        margin-left: 20px;
      }
    }
  }
  .input_box {
    position: relative;
    margin-left: auto;
    @include flex(y);
    @include media-max($m) {
      margin-top: 25px;
      width: 100%;
    }
    &.icon_search {
      &::after {
        content: "";
        display: inline-block;
        width: 20px;
        height: 20px;
        background: url("../../img/icon_search.png") no-repeat 0 0;
        background-size: contain;
        position: absolute;
        right: 15px;
      }
    }
    .input {
      width: 240px;
      padding: 0 40px 0 15px;
      @include media-max($m) {
        width: 100%;
      }
    }
  }
}

//!checkbox
.com_chk {
  display: none;
  & + label,
  & + label:before {
    cursor: pointer;
  }
  & + label {
    display: inline-block;
    padding-left: 23px;
    @include media-max($xl) {
      font-size: 13px;
    }
    &:before {
      content: "";
      display: inline-block;
      width: 18px;
      height: 18px;
      margin-right: 5px;
      margin-left: -23px;
      vertical-align: -5px;
      background: url(../../img/chk.png) no-repeat #e4e5ed center;
      background-size: 8.9px auto;
      border-radius: 100px;
    }
  }
  &:checked + label:before {
    background-color: $point;
  }
  &.circle {
    & + label {
      position: relative;
      width: 100%;
      padding-left: 0;
      &:before {
        background: url("../../img/radio_off.png") no-repeat;
        width: 20px;
        height: 20px;
        background-size: 20px auto;
        border: none;
        margin-right: 5px;
        vertical-align: -5px;
        border: none;
        position: absolute;
        left: 40px;
        top: 50%;
        margin-top: -10px;
      }
    }
    &:checked + label:before {
      background-image: url("../../img/radio_on.png");
    }
  }
  &.type_btn {
    & + label {
      padding: 10px 19px;
      font-size: 15px;
      letter-spacing: -0.38px;
      border-radius: 5px;
      background-color: #f5f7ff;
      border: 2px solid #f5f7ff;
      margin-right: 5px;
      margin-bottom: 5px;
      @include media-max($m) {
        padding: 5px 10px;
        font-size: 14px;
      }
      &:before {
        display: none;
      }
      & + .type_btn {
        & + label {
          margin-left: 0;
        }
      }
    }
    &:checked + label {
      border-color: $point;
      background-color: #fff;
      color: $point;
      font-weight: bold;
    }
  }
}

//! review grade
.com_star_box {
  position: relative;
  direction: rtl;
  text-align: center;
  margin-bottom: 19px;
  input[type="radio"] {
    display: none;
    &:checked {
      & ~ label {
        background-image: url(../../img/icon_star_active.png);
      }
    }
  }
  label {
    background: url(../../img/icon_star_off.png) no-repeat center;
    background-size: contain;
    width: 24px;
    height: 24px;
    display: inline-block;
    cursor: pointer;
    margin-right: 3px;
    margin-left: 0 !important;
    &:last-child {
      margin-right: 0;
    }
  }
}
